@use "../../../styles/colours";
@use "../../../styles/sizing";
@use "../../../styles/spacing";
@use "../../../styles/mixins";

.top-research-icons {
	display: grid !important;
    grid-template-columns: 44px 92px 52px;
    gap: 8px;
	justify-content: end;

	.popup-outer {
		display: inline-flex !important;
		align-self: center;
	}
}

.top-tickers-viewed-icons, .top-authors-viewed-icons {
    grid-template-columns: 48px;
}