@import "@threeskye/core-components/dist/styles/Variables.scss";
@import "../styles/EditorVariables.scss";

.threeskye-editor {

	.bold {
		font-weight: bold;
	}

	.italic {
		font-style: italic;
	}

	.underline {
		text-decoration: underline;
	}
 }