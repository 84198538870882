@use "../../styles/borders";
@use "../../styles/colours";
@use "../../styles/mixins";
@use "../../styles/sizing";
@use "../../styles/spacing";
@use "../../styles/typography";

.checkbox-group-wrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 80px;
	padding-bottom: 48px;
}

.checkbox-group {
	display: inline-block;
}

.checkbox-list {
	padding-left: spacing.$m;
	padding-top: spacing.$s;
	position: relative;
	transform: translateX(spacing.$s) translateY(spacing.$s);

	&::before {
		background-color: colours.$neutral-dark-20;
		position: absolute;
		left: 0;
		top: 0;
		content: "";
		width: 1px;
		height: calc(100% - 10px);
	}

}

.checkbox-list-wrapper {
	margin-top: spacing.$m;
	position: relative;

	&::before {
		background-color: colours.$neutral-dark-20;
		position: absolute;
		left: -1rem;
		top: 45%;
		transform: translateY(-50%);
		content: "";
		width: 12px;
		height: 1px;
	}

	&:first-of-type {
		margin-top: 0;
	}
}

.master-checkbox {
	font-weight: typography.$font-weight-medium;
}