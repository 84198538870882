@use "./colours.scss";

$width-default: 1px;
$width-medium: 1.5px;
$width-thick: 2px;

$colour-default: colours.$border-grey;
$colour-primary: colours.$border-primary;
$colour-neutral-blue: colours.$border-neutral-blue;
$colour-accent-1: colours.$border-accent-1;
$colour-accent-2: colours.$border-accent-2;
$colour-input-grey: colours.$border-input-grey;
$colour-button-grey: colours.$border-button-grey;

$style-default: solid;
$style-dashed: dashed;
$radius-default: 0.25rem;

$border-default: $width-default $style-default $colour-default;
$border-default-medium: $width-medium $style-default $colour-default;
$border-primary: $width-default $style-default $colour-primary;
$border-primary-dashed: $width-default $style-dashed $colour-primary;
$border-thick-primary-dashed: $width-thick $style-dashed $colour-primary;
$border-invalid: $width-default $style-default colours.$danger-light;
$border-danger: $width-default $style-default colours.$danger;
$border-danger-light: $width-default $style-default colours.$danger-light;
$border-danger-dark: $width-default $style-default colours.$danger-dark;
$border-positive: $width-default $style-default colours.$positive;
$border-positive-light: $width-default $style-default colours.$positive-light;
$border-positive-dark: $width-default $style-default colours.$positive-dark;
$border-warning: $width-default $style-default colours.$warning;
$border-warning-light: $width-default $style-default colours.$warning-light;
$border-warning-dark: $width-default $style-default colours.$warning-dark;
$border-danger: $width-default $style-default colours.$danger;
$border-neutral-blue: $width-default $style-default $colour-neutral-blue;
$border-dark-80: $width-default $style-default colours.$border-neutral-dark-80;
$border-dark-50: $width-default $style-default colours.$border-neutral-dark-50;
$border-dark-30: $width-default $style-default colours.$border-neutral-dark-30;
$border-transparent: $width-default $style-default transparent;
$border-thick: $width-thick $style-default $colour-default;
$border-thick-primary: $width-thick $style-default $colour-primary;
$border-thick-transparent: $width-thick $style-default transparent;