@use "../styles/colours";
@use "../styles/typography";
@use "../styles/mixins";
@use "../styles/spacing";
@use "../styles/borders";
@use "../styles/sizing";

.tag-token {
	.search-select-field__multi-value {
		background-color: colours.$bg-primary;
		border-radius: borders.$radius-default;
	}

	.search-select-field__multi-value__label {
		color: colours.$text-neutral-light;
		font-family: typography.$font-family-primary;
		font-size: typography.$font-size-s;
	}

	.search-select-field__multi-value__remove {
		color: colours.$text-neutral-light;
		cursor: pointer;

		svg {
			@include mixins.width-and-height(sizing.$m);
			opacity: 0.5;
		}

		&:hover {
			background: inherit;
			svg {
				color: colours.$text-neutral-light;
				opacity: 0.5;
			}
		}
	}
}