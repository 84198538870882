@use "../../styles/colours";
@use "../../styles/borders";
@use "../../styles/layout/layers";
@use "../../styles/typography";
@use "../../styles/mixins";
@use "../../styles/spacing";
@use "../../styles/sizing";
@use "../../styles/shadows";
@use "../../styles/animations";

.draft-note-preview-modal {
	align-self: flex-start;
    max-width: 100%;
    width: 100%;
    margin-top: -24px;
	background-color: transparent;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: row;
	box-shadow: none;

	.modal-close-icon {
		display: none;
	}
	
	.modal-content {
		padding-top: 0;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		flex-direction: row;
		gap: spacing.$m;
	}
	
	.preview-wrapper {
		box-shadow: shadows.$default;
		width: 1000px;
		background-color: #fff;
	}
	
	.floating-sidebar {
		border-radius: borders.$radius-default;
		box-shadow: shadows.$default;
		background-color: colours.$neutral-light;
		padding: spacing.$m;
		width: 359px;
		height: auto;
		position: sticky;
		top: 0px;
	}
}