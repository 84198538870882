@use "../styles/colours";
@use "../styles/borders";
@use "../styles/spacing";

.divider {
	background-color: borders.$colour-default;
	margin: spacing.$s 0;
	border: none;
	width: 100%;
	height: 1px;
	
	&.divider-thick {
		height: 2px;
	}

	&.divider-dark {
		background-color: colours.$neutral-dark-30;
	}
	
	&.divider-dashed {
		background-color: transparent;
		border: 1px dashed borders.$colour-default;
	}

	&.divider-vertical {
		height: auto;
		width: 1px;
		margin: 0;
	}
	

	&.spacing-none {
		margin: 0;
	}

	&.spacing-m {
		margin: spacing.$m 0;
	}

	&.spacing-l {
		margin: spacing.$l 0;
	}

	&.spacing-xl {
		margin: spacing.$xl 0;
	}

	&.spacing-top-s {
		margin-top: spacing.$s;
	}

	&.spacing-top-m {
		margin-top: spacing.$m;
	}

	&.spacing-top-l {
		margin-top: spacing.$l;
	}

	&.spacing-top-xl {
		margin-top: spacing.$xl;
	}

	&.spacing-bottom-s {
		margin-bottom: spacing.$s;
	}

	&.spacing-bottom-m {
		margin-bottom: spacing.$m;
	}

	&.spacing-bottom-l {
		margin-bottom: spacing.$l;
	}

	&.spacing-bottom-xl {
		margin-bottom: spacing.$xl;
	}

}