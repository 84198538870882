@import "@threeskye/core-components/dist/styles/Variables.scss";
@import "../styles/EditorVariables.scss";

.editor-edit-state-wrapper {
	position: relative;
	width: auto;
	height: auto;

	:not(.image-element) {
		outline: none !important;
	}

	&::before {
		content: "";
		position: absolute;
		left: 0;
		top: -2px;
		width: 100%;
		height: 100%;
		background-color: transparent;
		border-left: 2px solid transparent;
		transition: all 200ms ease;
		transform: scaleX(1.03) scaleY(1);
	}

	&.clicked {
		&::before {
			box-shadow: $edit-state-active-shadow;
			border-left-color: $colour-main;
		}

		.filled {
		// .RichEditor-root
		// .RichEditor-editor
		// .DraftEditor-root
		// .DraftEditor-editorContainer
		// .public-DraftEditor-content {
			background-color: transparent;

			&:hover {
				background-color: transparent;
			}
		}



	}
	
	&.overflown {
		&.clicked {
			&::before {
				box-shadow: $edit-state-active-shadow;
				border-left-color: $colour-invalid;
				background-color: $colour-invalid-focused;
			}
		}

		.filled{
		// .RichEditor-root
		// .RichEditor-editor
		// .DraftEditor-root
		// .DraftEditor-editorContainer
		// .public-DraftEditor-content {
			background-color: $colour-invalid-resting;

			&:hover {
				background-color: $colour-invalid-resting-hover;
				border-color: $colour-invalid-resting-hover-border;
			}

			&:focus {
				background-color: transparent !important;
			}
		}
	}

	&.overflown.clicked {
		.filled{
		// .RichEditor-root
		// .RichEditor-editor
		// .DraftEditor-root
		// .DraftEditor-editorContainer
		// .public-DraftEditor-content {
			background-color: transparent;

			&:hover {
				background-color: transparent;
				border-color: transparent;
			}
		}

		// .RichEditor-root
		// .RichEditor-editor
		// .DraftEditor-root
		// .DraftEditor-editorContainer
		// .public-DraftEditor-content {
		// 	background-color: transparent;

		// 	&:hover {
		// 		background-color: transparent;
		// 	}
		// }
	}
}

.editable-text {	
	border: 1px solid transparent;

	*::selection {
		background: $colour-text-selection;
	}

	h1 {
		margin-bottom: 0px;
	}

	figure {
		margin: 0px;
	}

	/* make the editor clickable anywhere within containing div */
	.threeskye-editor {
		height: 100%;
		padding: 0px;
		border: none;

		.editor-wrapper {
			height: 100%;
			width: 100%;
			min-height: inherit;
			padding: 0px;
			margin-top: 0px;
			border: none;
		

			div[data-slate-editor=true] {
				height: 100%;
				padding: 0px;
				margin: 0px;
				min-height: inherit;
				transition: all 100ms ease;
				position: relative;
				border: 1px solid transparent;
				background-color: $colour-resting;
				cursor: text;
				//overflow: hidden;
						
				&.overflow-enabled {
					overflow: visible;
				}

				&:hover {
					border-color: $colour-resting-hover-border;
					background-color: $colour-resting-hover;

					&:focus {
						background-color: transparent;
					}
				}

				&:focus {
					outline: none; 
					border-color: transparent;
					background-color: transparent !important;

					// cursor: text;
				}

				&:focus-within {
					background-color: transparent !important;
				}

				div[data-slate-node=element] img {
					width: 100%;
				}
			}

			.table-table.full-width {
				position: relative;
				left: 16pt;
				width: 550pt;
				background-color: white;
			}
			.image-image.full-width {
				position: relative;
				left: 16pt;
				width: 550pt;
				background-color: white;
			}
			.side-by-side.full-width {
				position: relative;
				left: 16pt;
				width: 550pt;
				background-color: white;

				.image-image.full-width {
					left:inherit;
					width: inherit;
				}
				.table-table.full-width {
					left:inherit;
					width: inherit;
				}
			}

		}

		
	}

	&:focus {
		.editor-wrapper {
			div[data-slate-editor=true] {
				background-color: transparent !important;
				border-color: transparent !important;
			}
		}
	}

	// .RichEditor-root {
	// 	height: 100%;
	// 	padding: 0px;
	// 	border: none;

	// 	.RichEditor-editor {
	// 		height: 100%;
	// 		width: 100%;
	// 		min-height: inherit;
	// 		padding: 0px;
	// 		margin-top: 0px;
	// 		border: none;

	// 		table.inserted {
	// 			border: inherit;
	// 		}

	// 		.doc__wysiwyg {
	// 			background-color: $colour-topbar-bg;
	// 			color: $colour-topbar-text;
	// 			margin-top: 10px;
	// 			padding: 5px;
	// 			z-index: 100;

	// 			.wysiwyg__button, .wysiwyg__button--active {
	// 				color: $colour-topbar-text;
	// 				font-size: 16px;
	// 				i {
	// 					font-size: 18px;
	// 				}
	// 			}
	// 			.wysiwyg__button {
	// 				background-color: transparent;

	// 				&:hover {
	// 					background-color: $colour-topbar-button-hover;
	// 				}
	// 			}
	// 			.wysiwyg__button--active {
	// 				background-color: $colour-sub-accent-dark;
	// 				color: #fff;
	// 			}
	// 			.wysiwyg__divider {
	// 				width: 12px;
	// 			}
	// 		}

			// .DraftEditor-root {
			// 	height: 100%;
			// 	margin: 0px;
			// 	padding: 0px;
			// 	border: none;
			// 	min-height: inherit;

			// 	.DraftEditor-editorContainer {
			// 		height: 100%;

			// 		// Main editor box with border and bg color.
			// 		.public-DraftEditor-content {
			// 			height: 100%;
			// 			padding: 0px;
			// 			margin: 0px;
			// 			min-height: inherit;
			// 			transition: all 100ms ease;
			// 			position: relative;
			// 			border: 1px solid transparent;
			// 			background-color: $colour-resting;
			// 			cursor: text;
			// 			overflow: hidden;
								
			// 			&.overflow-enabled {
			// 				overflow: visible;
			// 			}

			// 			&:hover {
			// 				border-color: $colour-resting-hover-border;
			// 				background-color: $colour-resting-hover;
			// 			}

			// 			&:focus {
			// 				outline: none; 
			// 				border-color: transparent;
			// 				background-color: #fff;
			// 				// cursor: text;
			// 			}
					// }
			// 	}
			// }
		// }
	// } 
	
	// &.filled
	// .RichEditor-root
	// .RichEditor-editor
	// .DraftEditor-root
	// .DraftEditor-editorContainer
	// .public-DraftEditor-content {
	// 	background-color: #fff;

		&:hover {
			background-color: $colour-resting;
		}
	// }
}



.DraftEditor-root td.draghandle {
	height: 1px !important;
}
