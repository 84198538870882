@import "./styles/EditorVariables.scss";

.flowing-page {
	padding: 0px;

	.page-boundary {
		position: absolute;
		left: 0;
		right: 0;
		height: 0px;
		border: 1px dashed rgba($colour-main, 0.5);
		transition: 200ms;

		// @Colin - Use this class for the red break
		.red-boundary {
			border: 1px dashed rgba($colour-invalid, 0.5);
		}

		// Tooltip wrapper
		.drag-handle-wrapper {
			&.left {
				.draghandle-tooltip {
					left: 8px;
				}
			}

			&.right {
				.draghandle-tooltip {
					right: 8px;
				}
			}

			&:hover {
				.draghandle-tooltip {
					display: block;
					opacity: 1;
				}
			}
		}

		// Tooltip
		.draghandle-tooltip {
			opacity: 0;
			position: absolute;
			top: -43px;
			font-size: 11px;
			padding: 8px 6px;
			border-radius: 6px;
			color: #fff;
			background-color: $colour-sub;
			transition: 300ms ease;
		}

		&.manual {
			border: 1px dashed rgba($colour-main, 1);

			.drag-handle-button {
				background-color: rgba($colour-main, 1);
				display: flex;
			}
		}

		&:hover {
			border-color: $colour-main;

			.drag-handle-button {
				display: flex;
			}
		}

		&.auto {
			.drag-handle-button {
				display: none;
			}
		}

		&.active {
			&:before {
				content: "";
				width: 100%;
				height: 10px;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				background-color: rgba($colour-main, 0.2);
			}
		}

		.drag-handle-button {
			width: 17px;
			height: 17px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
			background-color: rgba($colour-main, 0.3);
			position: absolute;
			left: 10px;
			top: 50%;
			transform: translateY(-50%);
			border-radius: 50%;
			cursor: grab;
			font-size: 15px;
			display: none;

			&.rhs {
				right: 10px;
				left: auto;
			}

			&:hover {
				transform: translateY(-50%);
				box-shadow: 0 0 4px rgba(#000, 0.3);
				background-color: rgba($colour-main, 1);
			}

			&:active {
				cursor: grabbing;
			}

		}
	}

	.editable-text .RichEditor-root .RichEditor-editor .DraftEditor-root .DraftEditor-editorContainer .public-DraftEditor-content {
		overflow: visible;
	}

	.image-wrapper {
		nav {
			display: flex;
			flex-direction: row;
			align-items: center;
		}
		.react-contextmenu .react-contextmenu-item {
			margin-top: 2px;
		}
	}
}

.react-contextmenu .react-contextmenu-item.page-width-menu-item {
	display: block;
}
.react-contextmenu .react-contextmenu-item.text-width-menu-item {
	display: none;
}

.page-width {

	.react-contextmenu .react-contextmenu-item.page-width-menu-item {
		display: none;
	}
	.react-contextmenu .react-contextmenu-item.text-width-menu-item {
		display: block;
	}
}

.image-header span[data-slate-zero-width] {
	min-width: 100%;
	background-color: rgba(13, 142, 253, 0.1);
	display: inline-block;
}
.image-footer span[data-slate-zero-width] {
	min-width: 100%;
	background-color: rgba(13, 142, 253, 0.1);
	display: inline-block;
}
.table-header span[data-slate-zero-width] {
	min-width: 100%;
	background-color: rgba(13, 142, 253, 0.1);
	display: inline-block;
}
.table-footer span[data-slate-zero-width] {
	min-width: 100%;
	background-color: rgba(13, 142, 253, 0.1);
	display: inline-block;
}