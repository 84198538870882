@use "../../styles/colours";
@use "../../styles/spacing";
@use "../../styles/sizing";
@use "../../styles/mixins";
@use "../../styles/typography";
@use "../../styles/animations";

.image-gallery-section {
	width: 100%;

	.gallery-header {
		width: 100%
	}

	.gallery-images {
		display: flex;
		gap: spacing.$l;
		width: 100%;
		flex-wrap: wrap;

	}
}