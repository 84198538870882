@use "../styles/colours";
@use "../styles/spacing";
@use "../styles/sizing";
@use "../styles/mixins";
@use "../styles/typography";

.error-message-wrapper {
	@include mixins.flex-center;
	flex-wrap: wrap;
	gap: sizing.$m;
	width: 100%;
	text-align: center;
	padding-top: 20vh;
	
	.error-header {
		@include mixins.flex-center;
		gap: spacing.$m;
		width: 100%;

		h1 {
			font-size: 80px;
			font-weight: typography.$font-weight-semi-bold;
		}
	
		svg {
			@include mixins.width-and-height(72px);
			color: colours.$primary;
		}
	}
	
	.error-message {
		p {
			font-size: typography.$font-size-header-3;
			font-weight: typography.$font-weight-medium;
		}

		width: 100%;
		text-align: center;
	}
}
