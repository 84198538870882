@use "../styles/colours";
@use "../styles/sizing";
@use "../styles/spacing";
@use "../styles/borders";
@use "../styles/mixins";
@use "../styles/typography";
@use "../styles/animations";

.ts-image-container {
	@include mixins.width-and-height(sizing.$xxl);
	border-radius: borders.$radius-default;
	overflow: hidden;
	position: relative;

	img {
		@include mixins.width-and-height(100%);
		transition: animations.$transition-default;
	}

	&.ts-image-size-m {
		@include mixins.width-and-height(sizing.$l);
	}

	&.ts-image-size-l {
		@include mixins.width-and-height(64px);
	}

	&.ts-image-size-xl {
		@include mixins.width-and-height(96px);
	}

	&.ts-image-size-xxl {
		@include mixins.width-and-height(112px);
	}

	.ts-image-overlay {
		opacity: 0;
		transition: animations.$transition-default;
	}

	&.ts-image-hover-upload {
		cursor: pointer;
		
		&:hover {
			img {
				transform: scale(1.08);
			}
	
			.ts-image-overlay {
				opacity: 1;
			}
		}
	}

	&.gallery-image {
		.ts-image-overlay {
			svg {
				display: none;
			}
		}
	}

	&.selected-image {
		outline: borders.$border-thick-primary;

		img {
			transform: scale(1.08);
		}

		.ts-image-overlay {
			opacity: 1;
		}
	}
}

.ts-image-overlay {
	@include mixins.width-and-height(100%);
	@include mixins.flex-center;
	background-color: colours.$bg-primary-overlay;
	position: absolute;
	left: 0;
	top: 0;

	svg {
		@include mixins.width-and-height(60%);
		max-width: sizing.$xxl;
		max-height: sizing.$xxl;
		color: colours.$text-neutral-light;
	}
}
