@use "../styles/mixins";
@use "../styles/colours";

.text-align-left {
	text-align: left;
}

.text-align-center {
	text-align: center;
}

.text-align-right {
	text-align: right;
}

.cursor-pointer {
	cursor: pointer;
}

.cursor-default {
	cursor: default;
}

.text-transform-capitalise {
	text-transform: capitalize;
}

.text-transform-uppercase {
	text-transform: uppercase;
}

.text-transform-lowercase {
	text-transform: lowercase;
}

.truncate {
	@include mixins.truncate(100%);
}

.text-link {
	text-decoration: underline;
	display: inline;
	cursor: pointer;

	&:hover {
		color: colours.$text-primary;
	}
}

.sorting-header {
	display: flex;
	gap: 2px;
	align-items: center;
	cursor: pointer;

	&.centered {
		justify-content: center;
	}

	&.right {
		justify-content: right;
	}

	&.left {
		justify-content: left;
	}

	&.extra-space {
		gap: 8px
	}
}

.empty-row {
	.nested-table-empty-td {
		height: 1rem !important;
	}

}

.nested-table-empty-td {
	height: 2.5rem !important;
	border-top: none !important;
	border-bottom: none !important;
	background-color: colours.$input-grey !important;
	padding: 0px !important;
}

.nested-table-div-in-td {
	padding: 0.75rem 0rem;
	border-top: 1px solid #E7EAF2;

	&.left {
		margin-left: 1.5rem;
	}

	&.right {
		margin-right: 1.5rem;
	}

	&.no-top {
		border-top: none !important;
	}
}

.expanded-table-row {
	background-color: colours.$input-grey !important;
	font-weight: 600;
}

.informative-icon {
	&:hover {
		color: colours.$text-primary;;
	}
}