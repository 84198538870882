@use "../../styles/colours";

.file-icon {
	width: 24px;

	path {
		fill: colours.$icon-grey;
	}
	
	&.no-hover {
		&.pdf {
			path {
				fill: colours.$pdf;
			}
		}
	
		&.xls {
			path {
				fill: colours.$xls;
			}
		}
		&.xml {
			path {
				fill: colours.$accent-2;
			}
		}
	}

	&.pdf {
		&:hover {
			path {
				fill: colours.$pdf;
			}
		}
	}

	&.xls {
		&:hover {
			path {
				fill: colours.$xls;
			}
		}
	}
	&.xml {
		&:hover {
			path {
				fill: colours.$accent-2;
			}
		}
	}
}

.icon-text {
	path {
		fill: #fff;
	}
}