@use "../styles/animations";
@use "../styles/components/inputs";
@use "../styles/colours";
@use "../styles/spacing";
@use "../styles/sizing";
@use "../styles/mixins";
@use "../styles/typography";
@use "../styles/shadows";
@use "../styles/borders";
@import "./EditorVariables.scss";

// NOTE: the order of these styles DO matter

.date-range-container {
	@include mixins.flex-align-center;
	gap: spacing.$l;
	font-family: typography.$font-family-primary;
	position: relative;

	input {
		font-family: typography.$font-family-primary;
	}

	.clear-icon {
		@include mixins.width-and-height(inputs.$size-icon-default);
		color: inputs.$colour-icon-default;
		cursor: pointer;
		position: absolute;
		right: spacing.$s;
		top: 50%;
		transform: translateY(-50%);

		&:hover {
			color: inputs.$colour-icon-default-focused;
		}
	}
	
}

// Day blocks
.CalendarDay__default {
	font-size: typography.$font-size-s;
	color: colours.$text-neutral-dark;
	border-color: transparent;
	background: transparent;

	&:hover {
		background: colours.$bg-primary;
		color: colours.$text-neutral-light;
		border-color: transparent;
	}
}

.CalendarDay__blocked_out_of_range, .CalendarDay__blocked_out_of_range:active, .CalendarDay__blocked_out_of_range:hover {
	opacity: 0.1;
	border-color: transparent;
	background: transparent;
}

// Month headers
.CalendarMonth_caption {
	color: colours.$text-neutral-dark;
	font-size: typography.$font-size-m;
	font-weight: typography.$font-weight-medium	;
}

// Day week headers
.DayPicker_weekHeader {
	color: colours.$text-neutral-dark;
	font-weight: typography.$font-weight-medium;
}

// Nav buttons 
.navigation-button {
	padding: spacing.$xs;
	border-radius: borders.$radius-default;
	position: absolute;
	top: 14px;
	line-height: .78;
	display: inline-flex;
	border: 1px solid transparent;

	svg {
		color: colours.$text-neutral-dark;
		width: sizing.$icon-s;
	}

	&.navigation-button__prev {
		left: 22px;
	}

	&.navigation-button__next {
		right: 22px;
	}

	&:hover {
		background-color: colours.$bg-primary;
		color: colours.$text-neutral-light;
	}

	&:active {
		background-color: rgba($colour-primary, 0.3);
	}
}

// Will edit everything selected including everything between a range of dates
.CalendarDay__selected_span {
	background: rgba(colours.$bg-primary, 0.3); //background
	color: colours.$text-neutral-light;
	border: none !important;

	&:active, &:hover {
		background: rgba(colours.$bg-primary, 0.5); //background
		border: 1px solid rgba(colours.$bg-primary, 0.7);
	}
}
  
// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
	color: colours.$text-neutral-light;
	background: colours.$bg-primary;
	font-weight: 600;
	
	&.CalendarDay__selected_start {
		background: colours.$bg-primary;
		border-top-left-radius: borders.$radius-default;
		border-bottom-left-radius: borders.$radius-default;
	}
	
	&.CalendarDay__selected_end {
		border-top-right-radius: borders.$radius-default;
		border-bottom-right-radius: borders.$radius-default;
	}
}
  
// Will edit when hovered over. _span style also has this property
.CalendarDay__selected:hover {
	background: rgba(colours.$bg-primary, 0.5);
	color: colours.$text-neutral-light;
}
  
// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
	background: rgba(colours.$bg-primary, 0.5);
	color: colours.$text-neutral-light;
	border: none !important;
}

// Change bg of entire calendar
.CalendarMonth, .DayPicker_focusRegion, .CalendarMonthGrid, .DateRangePicker_picker, .DayPicker__horizontal {
	background-color: colours.$bg-neutral-light;
}

.DateRangePicker_picker {
	border-radius: borders.$radius-default;
	box-shadow: shadows.$default;
	overflow: hidden;
}

// Input styling
.DateRangePicker {
	border-radius: borders.$radius-default;

	.DateRangePickerInput {
		@include mixins.flex-align-center;
		border-radius: borders.$radius-default;
		padding: inputs.$padding-default;
		padding-right: spacing.$xl;
		background-color: inputs.$colour-bg-default;
		border: inputs.$border-default;

		.DateRangePickerInput_calendarIcon, .CalendarMonthGrid button {
			width: auto !important;
			border: none !important;
			min-width: auto !important;
			padding: 0;
			margin: 0;
		}

		.calendar-icon {
			@include mixins.width-and-height(inputs.$size-icon-default);
			color: inputs.$colour-icon-default;
			margin-right: sizing.$m;
		}
	}

	.DateRangePickerInput_calendarIcon {
		&:hover {
			opacity: 1;
		}
	}

	.DateInput {
		background-color: transparent;
		width: 98px;
	}

	.arrow-icon {
		@include mixins.width-and-height(inputs.$size-icon-default);
		color: colours.$text-neutral-dark;
		transition: animations.$transition-default;
		margin: 0 15px 0 5px;
	}

	&:focus-within {
		.DateRangePickerInput {
			background-color: inputs.$colour-bg-default-focused;
			border: inputs.$border-default-focused;
			box-shadow: shadows.$default;
		}

		.DateInput_input {
			color: inputs.$colour-text-default-focused;

			&::placeholder {
				color: inputs.$colour-text-default-focused;
			}
		}

		.calendar-icon {
			color: inputs.$colour-icon-default-focused;
		}

		.arrow-icon {
			color: inputs.$colour-icon-default;
		}
	}
}

// Triangle styles
.DateInput_fangShape {
	fill: inputs.$colour-bg-default;
}

.DateInput_fangStroke {
	stroke: inputs.$colour-bg-default;
}

.DateInput_1 {
	position: relative;

	&::before {
		border-radius: borders.$radius-default;
		background-color: colours.$bg-primary;
		transition: animations.$transition-fast;
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		height: 1px;
		opacity: 0;
		width: 100%;
		display: block;
	}

	&:focus-within {
		&::before {
			height: 2px;
			opacity: 1;
		}
	}
}

#date-range-picker-start, #date-range-picker-end {
	&.DateInput_input {
		transition: animations.$transition-default;
		height: calc(inputs.$size-height-small - 2px);
		font-weight: inputs.$font-weight-default;
		font-size: inputs.$font-size-default;
		padding: 0;
		margin: 0;
		border: none;
		border-radius: 0;
		background-color: transparent;
		position: relative;

		&::placeholder {
			color: inputs.$colour-text-default-placeholder;
		}
	}
	
	&.DateInput_input__focused {
		&::placeholder {
			color: inputs.$colour-text-default-placeholder-focused;
		}
	}
}

.date-range-info-outer {
	padding: 0 spacing.$l;
	transform: translateY(-8px);	
}

.date-range-info-inner {
	padding: spacing.$m 0 spacing.$l 0;
	border-top: borders.$border-default;
}