// Useful mixins. How to use in stylesheets: '@use mixin-name();'

// Fully center and element using flex
@mixin flex-center {
    display: flex;
	align-items: center;
	justify-content: center;
}

// Line clamp
@mixin line-clamp($line-count) {
	display: -webkit-box;
    -webkit-line-clamp: $line-count;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

// Align center element using flex
@mixin flex-align-center {
    display: flex;
	align-items: center;
}

// Justify center element using flex
@mixin flex-justify-center {
    display: flex;
	justify-content: center;
}

// Add ellipses to text
@mixin truncate($truncation-boundary) {
    max-width: $truncation-boundary;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// Hide scrollbars
@mixin hide-scrollbars {
	&::-webkit-scrollbar {
	  width: 0 !important
	}
	-ms-overflow-style: none;
	scrollbar-width: none;
}

// Set width and height
@mixin width-and-height($dimensions) {
	width: $dimensions;
	height: $dimensions;
}

// Set element fullscreen
@mixin fullscreen($position) {
	width: 100vw;
	height: 100vh;
	position: $position;
	left: 0;
	top: 0;
}

// Remove default ul styling
@mixin ul-reset {
	padding: 0;
	margin: 0;
	list-style-type: none;
}

// Remove default li styling
@mixin li-reset {
	margin: 0;
	padding: 0;

	&::marker {
		content: "";
	}
}