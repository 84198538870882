@use "../styles/borders";
@use "../styles/colours";
@use "../styles/sizing";
@use "../styles/spacing";
@use "../styles/mixins";
@use "../styles/typography";
@use "../styles/animations";

.checkbox-field-wrapper {
	gap: sizing.$s;
	font-size: typography.$font-size-m;
	color: colours.$text-neutral-dark;
	display: inline-flex;
	align-items: center;
	cursor: pointer;
	
	&:hover {
		.checkbox-field-box {
			border: borders.$border-primary;
		}
	}

	&.checkbox-field-wrapper-checked {
		.checkbox-field-box {
			background-color: colours.$bg-primary;
			border: borders.$border-primary;
		}

		svg {
			opacity: 1;
			transform: scale(0.8) translateY(0);
		}
	}

	&.checkbox-disabled {
		cursor: default;
		pointer-events: none;
	}
}

.checkbox-field-box {
	@include mixins.width-and-height(sizing.$m);
	@include mixins.flex-center;
	transition: animations.$transition-fast;
	border: borders.$border-dark-30;
	border-radius: borders.$radius-default;
	background-color: transparent;
	
	svg {
		@include mixins.width-and-height(14px);
		color: colours.$text-neutral-light;
		transition: animations.$transition-default;
		opacity: 0;
		transform: scale(0.8) translateY(5px);
	}
}