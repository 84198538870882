@use "../../styles/borders";
@use "../../styles/colours";
@use "../../styles/sizing";
@use "../../styles/spacing";
@use "../../styles/mixins";
@use "../../styles/typography";
@use "../../styles/animations";

.comments-outer-container {
	width: 100%;

}

.single-comment, .single-reply {
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
	padding:0.5rem 0px 0.5rem 1rem;
	width: 100%;
}

.comment-header, .reply-header {
	display: flex;
}

.comment-author, .reply-author {
	font-size: 1rem;
}
.comment-author-image-initials, .reply-author-image-initials {
	width: 32px; 
	height: 32px;
	border-radius: 16px;
	margin-right: 0.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: colours.$accent-1;
	color: colours.$text-neutral-light;
	min-width: 32px;
}

.comment-author-image, .reply-author-image {
	width: 32px; 
	height: 32px;
	border-radius: 16px;
	margin-right: 0.5rem;
}

.comment-header-info, .reply-header-info {
	width: 100%;
}

.comment-time, .reply-time {
	font-size: 0.8rem;
	color: rgba(0, 0, 0, 0.5);
}

.comment-header-info-top-row, .reply-header-info-top-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.comment-header-info-bottom-row, .reply-header-info-bottom-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.comment-message-body {
}
.reply-message-body {	
}

.comment-footer {
	margin-bottom: 0.25rem;
	display: flex;
	align-items: center;
	font-size: 0.8rem;
	color: rgba(0, 0, 0, 0.5);
	line-height: 24px;
	cursor: pointer;
}

.single-comment {
	background-color: colours.$bg-neutral-blue;
	// border-top: colours.$border-neutral-dark-30 solid 1px;
	padding-top: 1rem;
	padding-right: 1rem;
}

.last-comment {
	border-bottom: colours.$border-neutral-dark-30 solid 1px;
}

.first-comment {
	border-top: colours.$border-neutral-dark-30 solid 1px;
}

.single-reply {
	padding-left: 1rem;
	padding-top: 0.75rem;
}

.comments-input-container {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 100%;

	.text-area-container {
		width: 100%;
	}

}

.add-comment-text-area {
	width: 100%;
	min-height: 10rem;
}
