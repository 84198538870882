@use "../../styles/colours";
@use "../../styles/spacing";
@use "../../styles/sizing";

.tooltip-popup-wrapper {
	padding: spacing.$m;

	&.padding-xs {
		padding: spacing.$xs;
	}

	&.padding-s {
		padding: spacing.$s;
	}

	&.padding-l {
		padding: spacing.$s;
	}

	&.padding-xl {
		padding: spacing.$s;
	}
}

.tooltip-with-icon {
	display: flex;
	align-items: center;

	svg {
		width: sizing.$m;
		margin-right: spacing.$s;
		color: colours.$bg-primary;
	}
}