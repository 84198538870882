@use "../../styles/animations";
@use "../../styles/colours";
@use "../../styles/spacing";
@use "../../styles/sizing";
@use "../../styles/mixins";

.no-results-message-container {
	@include mixins.flex-center;
	flex-wrap: wrap;
	flex-direction: column;
	gap: spacing.$m;
	animation: animations.$transition-default;
	width: 100%;

	svg {
		color: colours.$text-primary;
		@include mixins.width-and-height(sizing.$icon-xl);
	}

	.sub-message {
		width: 100%;
		text-align: center;
	}
	
	&.no-results-compact {
		flex-direction: row;

		svg {
			@include mixins.width-and-height(sizing.$icon-m);
		}
	}
}