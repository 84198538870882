@use "../../styles/components/buttons";
@use "../../styles/borders";
@use "../../styles/colours";
@use "../../styles/animations";
@use "../../styles/mixins";

.button-base {
	@include mixins.flex-center;
	border-radius: buttons.$border-radius-default;
	height: buttons.$size-height-default;
	padding: buttons.$padding-default;
	font-family: buttons.$font-family-default;
	font-size: buttons.$font-size-default;
	transition: animations.$transition-default;
	white-space: nowrap;
	min-width: 88px;
	cursor: pointer;

	&:focus-visible {
		outline: borders.$border-primary;
	}

	&.button-no-hover {
		cursor: default;
		pointer-events: none;
		
		&:hover {
			background-color: buttons.$colour-bg-primary;
			color: buttons.$colour-text-primary;
			border: buttons.$border-primary;
		}
	}
}

.button-default {
	background-color: buttons.$colour-bg-primary;
	color: buttons.$colour-text-primary;
	border: buttons.$border-primary;

	&:hover {
		background-color: buttons.$colour-bg-primary-hover;
		color: buttons.$colour-text-primary-hover;
		border: buttons.$border-primary-hover;
	}

	&.button-no-hover {
		cursor: default;
		pointer-events: none;

		&:hover {
			background-color: buttons.$colour-bg-primary;
			color: buttons.$colour-text-primary;
			border: buttons.$border-primary;
		}
	}

	&:focus-visible {
		outline-color: borders.$colour-accent-2;
	}
}

.button-variant-secondary {
	background-color: buttons.$colour-bg-secondary;
	color: buttons.$colour-text-secondary;
	border: buttons.$border-secondary;

	&:hover {
		background-color: buttons.$colour-bg-secondary-hover;
		color: buttons.$colour-text-secondary-hover;
		border: buttons.$border-secondary-hover;
	}	
}

.button-variant-danger {
	background-color: buttons.$colour-bg-danger;
	color: buttons.$colour-text-danger;
	border: buttons.$border-danger;

	&:hover {
		background-color: buttons.$colour-bg-danger-hover;
		color: buttons.$colour-text-danger-hover;
		border: buttons.$border-danger-hover;
	}	
}

.button-variant-warning {
	background-color: buttons.$colour-bg-warning;
	color: buttons.$colour-text-warning;
	border: buttons.$border-warning;

	&:hover {
		background-color: buttons.$colour-bg-warning-hover;
		color: buttons.$colour-text-warning-hover;
		border: buttons.$border-warning-hover;
	}	
}

.button-variant-positive {
	background-color: buttons.$colour-bg-positive;
	color: buttons.$colour-text-primary;
	border: buttons.$border-positive;

	&:hover {
		background-color: buttons.$colour-bg-positive-hover;
		color: buttons.$colour-text-positive-hover;
		border: buttons.$border-positive-hover;
	}	
}

.button-variant-positive-light {
	background-color: buttons.$colour-bg-positive-light;
	color: buttons.$colour-text-positive-dark;
	border: buttons.$border-positive-light;

	&:hover {
		background-color: buttons.$colour-bg-positive-hover;
		color: buttons.$colour-text-positive-hover;
		border: buttons.$border-positive-hover;
	}	
}

.button-variant-outlined {
	background-color: buttons.$colour-bg-outlined;
	color: buttons.$colour-text-outlined;
	border: buttons.$border-outlined;

	&:hover {
		background-color: buttons.$colour-bg-outlined-hover;
		color: buttons.$colour-text-outlined-hover;
		border: buttons.$border-outlined-hover;
	}

	&.button-variant-outlined-light {
		color: buttons.$colour-text-outlined-light;
	}
}

.button-variant-transparent {
	background-color: buttons.$colour-bg-transparent;
	color: buttons.$colour-text-transparent;
	border: buttons.$border-transparent;

	&:hover {
		background-color: buttons.$colour-bg-transparent-hover;
		color: buttons.$colour-text-transparent-hover;
		border: buttons.$border-transparent-hover;
	}

	&.button-variant-transparent-light {
		color: buttons.$colour-text-transparent-light;

		&:hover {
			color: buttons.$colour-text-transparent-hover;
		}
	}
}

.button-disabled {
	// background-color: buttons.$colour-bg-disabled;
	// color: buttons.$colour-text-disabled;
	// border: buttons.$border-disabled;
	opacity: 0.5;
	pointer-events: none;
	transition: none;

	&:hover {
		color: buttons.$colour-text-disabled;
		border: buttons.$border-disabled;
	}

	&.primary {
		background-color: buttons.$colour-bg-primary;		
		color: buttons.$colour-text-primary;
		border: buttons.$border-primary;
		opacity: 0.5;
	}
}

.button-hover-default {
	&:hover {
		background-color: buttons.$colour-bg-primary-hover;
		color: buttons.$colour-text-primary-hover;
		border: buttons.$border-primary-hover;
	}
}

.button-hover-transparent {
	&:hover {
		background-color: buttons.$colour-bg-transparent;
		color: colours.$text-primary;
		border: buttons.$border-transparent;
	}	
}

.button-hover-danger {
	&:hover {
		background-color: buttons.$colour-bg-danger;
		color: buttons.$colour-text-danger;
		border: buttons.$border-danger;
	}	
}

.button-hover-danger-dark {
	&:hover {
		background-color: buttons.$colour-bg-danger-hover;
		color: buttons.$colour-text-danger-hover;
		border: buttons.$border-danger-hover;
	}	
}

.button-with-icon {
	svg {
		width: buttons.$size-icon-default;
		height: buttons.$size-icon-default;
		margin: buttons.$margin-icon-default;
	}
}

.button-icon-only {
	min-width: 56px;
	svg {
		margin: 0;
	}
}

.button-full-width {
	width: 100%;
	display: flex;
	justify-content: center;
}

.button-size-small {
	font-size: buttons.$font-size-small;
}