@use "../../styles/colours";
@use "../../styles/borders";
@use "../../styles/layout/layers";
@use "../../styles/typography";
@use "../../styles/mixins";
@use "../../styles/spacing";
@use "../../styles/sizing";
@use "../../styles/shadows";
@use "../../styles/animations";

.modal {
	background-color: colours.$bg-neutral-light;
	padding: spacing.$l;
	border-radius: borders.$radius-default;
	animation: animations.$transition-fade-in;
	box-shadow: shadows.$default;
	width: 60vw;
	min-width: 30vw;
	max-width: 640px;

	&.padding-none {
		padding: 0;
	}

	&.padding-s {
		padding: spacing.$s;
	}

	&.padding-m {
		padding: spacing.$m;
	}

	&.padding-xl {
		padding: spacing.$xl;
	}
}

.modal-close-icon {
	@include mixins.width-and-height(sizing.$icon-s);
	color: colours.$text-neutral-dark-30;
	cursor: pointer;
	
	&:hover {
		color: colours.$text-neutral-dark-80;
	}
}

.modal-header {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.modal-content {
	padding-top: spacing.$l;
}

.modal-buttons {
	padding-top: spacing.$xxl;
	display: flex;
	justify-content: flex-end;
}