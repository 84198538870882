.portal-access-radio{
	display: flex;
	flex-direction: column;
}

.portal-access-radio-options{
	display: flex;
	flex-direction: row	;
	margin-top: 1rem;
	gap: 1rem;
}

.portal-access-radio {
	transform: translateY(-2px);
}
.portal-access-radio-label{
	display: flex;
	flex-direction: row;
}

.invite-to-portal {
	width: fit-content;
}

.invite-to-portal-icon {
	width: 2rem;
}