@use "../../../styles/colours";
@use "../../../styles/mixins";
@use "../../../styles/spacing";
@use "../../../styles/borders";
@use "../../../styles/layout/layers";

.top-banner-extension-outter {
    background-color: colours.$bg-neutral-light;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    grid-area: topbar-extension;
}

.top-banner-extension {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    width: 100%;
    background-color: colours.$bg-neutral-light;
    border-bottom: 1.5px solid #E7EAF2;
    height: 100%;
    // overflow-x: scroll;
}

.top-banner-ext-header {
    display: flex;
    gap: spacing.$s;
    align-items: center;
    padding-right: 1.5rem;
    color: colours.$text-neutral-dark;
    border-right: borders.$border-default;

    &.no-tabs {
        border-right: none;
    }

    &.short-banner {
        padding-bottom: 1.75rem;
        padding-top: 0.25rem;
        height: calc(80px - 1.5rem);
        margin-bottom: 1rem;
    }

    &.border-bottom {
        border-bottom: borders.$border-default-medium;
    }

    &.no-margin {
        margin-bottom: -1.5rem;
    }
}

.top-banner-ext-links {
    display: flex;
    gap: spacing.$xl;
    align-items: center;
}

.top-banner-ext-link {
    color: colours.$text-neutral-dark-30;
    cursor: pointer;

    &.selected {
        color: colours.$primary;
    }

    &.truncate {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 20vw;
    }
}