@use "../../styles/colours";
@use "../../styles/spacing";
@use "../../styles/sizing";
@use "../../styles/mixins";
@use "../../styles/typography";

.image-gallery-wrapper {
	gap: spacing.$s;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	width: 100%;
}