@use "../../../styles/borders";
@use "../../../styles/spacing";
@use "../../../styles/typography";
@use "../../../styles/colours";

.separator {
    border-top: borders.$width-default borders.$style-default borders.$colour-default;
}

.sidebar-section {
    padding: spacing.$xl 0px;

    &:first-of-type {
        padding-top: spacing.$l;
    }
    
    &.no-padding {
        padding: 0 !important;
    }
}