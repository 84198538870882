@use "../../styles/spacing";
@use "../../styles/typography";
@use "../../styles/mixins";
@use "../../styles/colours";
@use "../../styles/sizing";

.profile-menu-header-container {
	gap: spacing.$s;
	display: flex;
	align-items: center;

	.profile-image-container, img {
		@include mixins.width-and-height(sizing.$l);
	}
}

.profile-menu-details {
	width: 76%;
}

.profile-header {
	@include mixins.truncate(100%);
	margin-bottom: spacing.$xs;
	font-size: typography.$font-size-s;
	font-weight: typography.$font-weight-semi-bold;
	color: colours.$text-neutral-dark;
}

.profile-sub-header {
	font-size: typography.$font-size-popup-menu-sub-header;
	color: colours.$text-label-grey;
	word-wrap: break-word;
}
