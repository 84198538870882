@use "../colours.scss";
@use "../borders.scss";
@use "../shadows.scss";
@use "../sizing.scss";
@use "../spacing.scss";
@use "../animations.scss";
@use "../typography.scss";
@use "../layout/layers.scss";

// Primary
$colour-bg-primary: colours.$bg-primary;
$colour-text-primary: colours.$text-neutral-light;
$colour-bg-primary-hover: colours.$bg-accent-2;
$colour-text-primary-hover: colours.$text-neutral-light;

// Secondary
$colour-bg-secondary: colours.$bg-button-grey;
$colour-text-secondary: colours.$text-neutral-dark;
$colour-bg-secondary-hover: colours.$bg-accent-2;
$colour-text-secondary-hover: colours.$text-neutral-light;

// Outlined
$colour-bg-outlined: transparent;
$colour-text-outlined: colours.$text-neutral-dark;
$colour-bg-outlined-hover: colours.$bg-primary;
$colour-text-outlined-hover: colours.$text-neutral-light;
$colour-text-outlined-light: colours.$text-neutral-light;

// Transparent
$colour-bg-transparent: transparent;
$colour-text-transparent: colours.$text-neutral-dark;
$colour-bg-transparent-hover: colours.$bg-button-grey;
$colour-text-transparent-hover: colours.$text-neutral-dark;
$colour-text-transparent-light: colours.$text-neutral-light;

// Danger
$colour-bg-danger: colours.$bg-danger;
$colour-text-danger: colours.$text-neutral-light;
$colour-bg-danger-hover: colours.$bg-danger-dark;
$colour-text-danger-hover: colours.$text-neutral-light;
$border-danger: borders.$width-default borders.$style-default colours.$danger;
$border-danger-hover: borders.$width-default borders.$style-default colours.$bg-danger-dark;

// Success
$colour-bg-positive: colours.$bg-positive;
$colour-bg-positive-light: colours.$bg-positive-light;
$colour-text-positive: colours.$text-neutral-light;
$colour-text-positive-dark: colours.$text-positive-dark;
$colour-bg-positive-hover: colours.$bg-positive-dark;
$colour-text-positive-hover: colours.$text-neutral-light;
$border-positive: borders.$width-default borders.$style-default colours.$positive;
$border-positive-light: borders.$width-default borders.$style-default colours.$positive-light;
$border-positive-hover: borders.$width-default borders.$style-default colours.$bg-positive-dark;

// Warning
$colour-bg-warning: colours.$bg-warning-light;
$colour-text-warning: colours.$text-warning-dark;
$colour-bg-warning-hover: colours.$bg-warning-dark;
$colour-text-warning-hover: colours.$text-neutral-light;
$border-warning: borders.$width-default borders.$style-default colours.$warning-light;
$border-warning-hover: borders.$width-default borders.$style-default colours.$bg-warning-dark;

// Disabled
$colour-bg-disabled: rgba(colours.$bg-button-grey, 0.5);
$colour-text-disabled: rgba(colours.$text-neutral-dark, 0.2);

// Border
$border-radius-default: borders.$radius-default;
$border-primary: borders.$width-default borders.$style-default colours.$primary;
$border-primary-hover: borders.$width-default borders.$style-default borders.$colour-accent-2;
$border-secondary: borders.$width-default borders.$style-default borders.$colour-button-grey;
$border-secondary-hover: borders.$width-default borders.$style-default borders.$colour-accent-2;
$border-outlined: borders.$width-default borders.$style-default colours.$primary;
$border-outlined-hover: borders.$width-default borders.$style-default colours.$primary;
$border-transparent: borders.$width-default borders.$style-default transparent;
$border-transparent-hover: borders.$width-default borders.$style-default borders.$colour-button-grey;
$border-disabled: borders.$width-default borders.$style-default rgba(borders.$colour-button-grey, 0.5);

// Font
$font-size-default: typography.$font-size-m;
$font-size-small: typography.$font-size-s;
$font-family-default: typography.$font-family-primary;
$font-weight-default: typography.$font-weight-regular;
$font-weight-medium: typography.$font-weight-medium;

// Spacing
$padding-default: 0 spacing.$m;
$margin-icon-default: 0 spacing.$s 0 0;

// Size
$size-icon-default: sizing.$icon-s;
$size-height-default: sizing.$xl;