@use "../../styles/borders";
@use "../../styles/colours";
@use "../../styles/mixins";
@use "../../styles/sizing";
@use "../../styles/spacing";
@use "../../styles/typography";
@import "../../styles/EditorVariables.scss";

.expandable-table-row {
	&.expand-active {
		td {
			font-weight: typography.$font-weight-semi-bold;
			background-color: colours.$bg-neutral-blue;
			border-bottom: none;
		}
	}

	&.invalid {
		background-color: $colour-invalid-resting;
		&:hover {
			background-color: $colour-invalid-resting;
		}
		td {
			background-color: $colour-invalid;
			&:hover {
				background-color: $colour-invalid;
			}
		}
	}
}

.expand-content-row {
	display: none;

	.expand-inner {
		padding: spacing.$l spacing.$xl !important;
		background-color: colours.$bg-neutral-blue;
		border-bottom: borders.$border-thick;
	}

	&.expand-active {
		display: table-row;
	}
}