@use "../../styles/colours";
@use "../../styles/animations";

.show-more-button {
	&.button-active {
		p, svg {
			color: colours.$neutral-dark;
		}

		svg {
			transform: rotate(180deg);
		}
	}

	p {
		width: 88px;
	}

	p, svg {
		color: colours.$neutral-dark-40;
		transition: animations.$transition-default;
    }

    &:hover {
		p, svg {
			color: colours.$neutral-dark-80;
		}
    }
}