@use "../../../styles/colours";
@use "../../../styles/spacing";
@use "../../../styles/mixins";
@use "../../../styles/sizing";
@use "../../../styles/borders";

.page-grid-item {
	&.item-disabled {
		pointer-events: none;
		opacity: 0.5;
	}
}

.justify-content-start, .justify-content-between, .justify-content-end, .align-items-start, .align-items-end, .align-items-center, .flex-direction-row, .flex-direction-col  {
	display: flex;
}

.flex-direction-row {
	flex-direction: row;
}

.flex-direction-col{
	flex-direction: column;
}

.justify-content-start {
	justify-content: flex-start;
}

.justify-content-between {
	justify-content: space-between;
}

.justify-content-end {
	justify-content: flex-end;
}

.align-items-start {
	align-items: flex-start;
}

.align-items-end {
	align-items: flex-end;
}

.align-items-center {
	align-items: center;
}

.wrap {
	flex-wrap: wrap;
}

.gap-s {
	gap: sizing.$s;
}

.gap-m {
	gap: sizing.$m;
}

.gap-l {
	gap: sizing.$l;
}

.gap-xl {
	gap: sizing.$xl;
}