@use "../../styles/borders";
@use "../../styles/colours";
@use "../../styles/sizing";
@use "../../styles/spacing";
@use "../../styles/mixins";
@use "../../styles/typography";
@use "../../styles/animations";

.page-content-toggle {
	.toggle-label {
		position: relative;
		padding-bottom: spacing.$s;
		font-weight: typography.$font-weight-medium;
		opacity: 0.3;
		cursor: pointer;
		
		&::after {
			content: "";
			height: 0;
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			background-color: colours.$bg-primary;
			border-radius: 10px;
			transition: 100ms;
		}

		&.toggle-label-active {
			opacity: 1;

			&::after {
				height: 3px;
			}
		}
	}
}