@use "../../../styles/colours";
@use "../../../styles/typography";
@use "../../../styles/borders";
@use "../../../styles/shadows";

.recharts-cartesian-axis-tick-value {
	font-size: typography.$font-size-s;
	fill: colours.$neutral-dark-70;
	font-family: typography.$font-family-primary;
	font-weight: typography.$font-weight-medium;
}

.recharts-tooltip-wrapper {
	.recharts-default-tooltip {
		border: none !important;
		outline: none;
		border-radius: borders.$radius-default;
		box-shadow: shadows.$default;

		.recharts-tooltip-label {
			font-size: typography.$font-size-m;
		}
	}

	.recharts-tooltip-item-list {
		font-size: typography.$font-size-header-3;

		.recharts-tooltip-item-name, .recharts-tooltip-item-value {
			font-weight: typography.$font-weight-medium;
		}
	}
}

.recharts-dot {
	fill: #fff;
	stroke: colours.$primary;
}