@use "../../styles/borders";
@use "../../styles/colours";
@use "../../styles/sizing";
@use "../../styles/spacing";
@use "../../styles/mixins";
@use "../../styles/typography";
@use "../../styles/animations";
@use "../../styles/layout/layers";

.dropzone {
    @include mixins.flex-center;
    background-color: rgba(colours.$bg-primary, 0.1);
    border: borders.$border-primary-dashed;
    border-radius: borders.$radius-default;
    padding: spacing.$xl;
    transition: animations.$transition-default;
    gap: sizing.$m;
    flex-wrap: wrap;
    min-height: 100px;
    cursor: pointer;
    
    .upload-icon {
        color: colours.$text-primary;
        border-radius: borders.$radius-default;
    }

    &.dropzone-small{
        padding: spacing.$s;
        min-height: 24px;
        .dropzone-message {
            font-size: typography.$font-size-s;
        }
    }

    &.dropzone-hidden{
        background-color: colours.$bg-neutral-light;
        border-color: colours.$bg-neutral-light ;
        width: fit-content;

        &:hover {
            background-color: rgba(colours.$bg-primary, 0.0);
            .dropzone-message {
                color: colours.$text-accent-1;
            }
        }
    }

    &:hover {
        background-color: rgba(colours.$bg-primary, 0.3);
    }
    
    &:active {
        background-color: rgba(colours.$bg-primary, 0.3);
    }

    &.uploading {
        cursor: default;
        flex-direction: column;
        pointer-events: none;

        &:hover {
            background-color: rgba(colours.$bg-primary, 0.5);
        }
    }
}

.dropzone-wrapper {
    &.drag-active {
        transform: scale(0.98);
        opacity: 0;
        visibility: hidden;
        transition: animations.$transition-fast;
    }
}

.dropzone-message {
    color: colours.$text-neutral-dark;
    font-size: typography.$font-size-m;
    text-align: center;
}

.sp-circle {
    @include mixins.width-and-height(sizing.$l);
	border: 4px rgba(colours.$primary , 0.25) solid;
	border-top: 4px rgba(colours.$primary , 1) solid;
	clear: both;
	border-radius: 50%;
	-webkit-animation: spCircRot .6s infinite linear;
	animation: spCircRot .6s infinite linear;
}

@-webkit-keyframes spCircRot {
	from { -webkit-transform: rotate(0deg); }
	to { -webkit-transform: rotate(359deg); }
}

@keyframes spCircRot {
	from { transform: rotate(0deg); }
	to { transform: rotate(359deg); }
}

.drop-zone-lz {
    @include mixins.flex-center;
    background-color: rgba(colours.$bg-primary, 0.1);
    color: colours.$text-neutral-dark;
    font-size: typography.$font-size-header-3;
    font-weight: typography.$font-weight-medium;
    border: borders.$border-primary-dashed;
    border-radius: borders.$radius-default;
    z-index: layers.$layer-10;
    transition: animations.$transition-fast;
    position: fixed;
    left: 2rem;
    top: 2rem;
    left: 23rem;
    top: 4rem;
    width: calc(100vw - 24rem);
    height: calc(100vh - 5rem);
    transform: scale(0.98);
    opacity: 0;
    visibility: hidden;

    svg {
        @include mixins.width-and-height(sizing.$xl);
        color: colours.$text-primary;
        margin-right: spacing.$m;
    }

    &.zone-active {
        opacity: 1;
        visibility: visible;
        transform: scale(1);
    }
}