$metropolis-fonts: (
	Thin: 100,
	ExtraLight: 200,
	Light: 300,
	Regular: 400,
	Medium: 500,
	SemiBold: 600,
	Bold: 700,
	ExtraBold: 800,
	Black: 900
);

@each $font-name, $font-weight in $metropolis-fonts {
	@font-face {
		font-family: "Metropolis";
		src: url("../resources/webfonts/WOFF2/Metropolis-#{$font-name}.woff2") format("woff2"), url("../resources/webfonts/WOFF/Metropolis-#{$font-name}.woff") format("woff"), url("../resources/webfonts/EOT/Metropolis-#{$font-name}.eot") format("eot");
		font-weight: $font-weight;
	}
}

// Family
$font-family-primary:  "Metropolis", sans-serif !important;

// Sizes
$font-size-xs: 0.74rem;
$font-size-s: 0.85rem;
$font-size-m: 1rem;
$font-size-l: 1.2rem;
$font-size-header-1: 1.953rem;
$font-size-header-2: 1.563rem;
$font-size-header-3: 1.25rem;
$font-size-header-4: 1rem;
$font-size-header-5: 0.800rem;
$font-size-header-6: 0.640rem;
$font-size-input-label: 1rem;
$font-size-field-label: 0.85rem;
$font-size-popup-menu-header: 0.8rem;
$font-size-popup-menu-sub-header: 0.64rem;

// weights
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

// Line-height
$line-height-s: 1rem;
$line-height-m: 1.3rem;
$line-height-l: 2rem;