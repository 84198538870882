@use "../../styles/typography";
@use "../../styles/colours";
@use "../../styles/spacing";
@use "../../styles/animations";

.status-widget-wrapper {
	width: 376px;
	border-radius: 100px;
	height: 34px;
	background-color: colours.$accent-1;
	color: colours.$text-neutral-light;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
	padding: 0 0.7rem;
	transition: 200ms ease;
	animation: animations.$transition-fade-in-slow;

	svg {
		width: 20px;
		height: 20px;
		animation: animations.$transition-fade-up-slow;
	}

	&.danger {
		color: colours.$text-danger-dark;
		background-color: colours.$text-danger-light;
	}

	.icon-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.info-wrapper {
		font-size: typography.$font-size-m;
		transform: translateY(0.5px);
		
		.label-wrapper {
			font-weight: typography.$font-weight-semi-bold;
			animation: animations.$transition-fade-up-slow;
		}
		
		.message-wrapper {
			animation: animations.$transition-fade-up-slow;
		}
	}

	.loading-icon-container {
		height: 20px;
	}
}