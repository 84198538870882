@use "../../styles/components/inputs";
@use "../../styles/typography";
@use "../../styles/mixins";
@use "../../styles/borders";
@use "../../styles/sizing";
@use "../../styles/spacing";
@use "../../styles/animations";
@use "../../styles/colours";

// Styles/classes must be nested within `search-select-field` to overwrite default styles
.search-select-field, .search-select-field__menu-portal {
	width: 100%;
	display: inline-block;

	&.search-field-size-small {
		.search-select-field__control {
			min-height: inputs.$size-height-small;
		}
	}

	&.search-field-width-s {
		width: 176px;
	}
	
	&.search-field-width-m {
		width: 240px;
	}
	
	&.search-field-width-l {
		width: 312px;
	}

	&.search-field-min-width {
		min-width: 360px;
	}

	.search-select-field__control, .search-select-field__menu {
		font-family: typography.$font-family-primary;
		background-color: inputs.$colour-bg-default;
		min-height: inputs.$size-height-default;
		border: inputs.$border-default;
		border-radius: borders.$radius-default;
		transition: animations.$transition-default;
		cursor: text;
		
		&:hover {
			border: inputs.$border-default;

			.search-select-field__placeholder {
				color: inputs.$colour-text-default-placeholder-hover;
			}
		}

		&.search-select-field__control--is-focused {
			background-color: inputs.$colour-bg-default-focused;
			box-shadow: inputs.$shadow-default-focused;
			border: inputs.$border-default-focused;

			.search-select-field__placeholder {
				color: inputs.$colour-text-default-placeholder-focused;
			}

			.search-icon {
				color: inputs.$colour-icon-default-focused;
			}
		}
		
	}
	
	.search-select-field__placeholder {
		color: inputs.$colour-text-default-placeholder;
	}

	.search-select-field__input {
		color: inputs.$colour-text-default;

		input {
			font-family: typography.$font-family-primary;
		}
	}

	.search-select-field__single-value {
		color: inputs.$colour-text-default-focused;
	}

	.search-select-field__indicators {
		margin-right: spacing.$s;

		svg {
			@include mixins.width-and-height(inputs.$size-icon-default);
			color: inputs.$colour-icon-default;
			cursor: pointer;

			&:hover {
				color: inputs.$colour-icon-default-focused;
			}
		}
	}

	.search-select-field__menu {
		@include mixins.hide-scrollbars;
		background-color: inputs.$colour-bg-default-focused;
		border-radius: inputs.$border-radius-default;
		box-shadow: inputs.$shadow-default-focused;
		animation: animations.$transition-fade-down-offset;
	}

	&.search-field-icon {
		.search-icon {
			@include mixins.width-and-height(inputs.$size-icon-default);
			left: spacing.$s;
			color: inputs.$colour-icon-default;
			position: absolute;
		}

		.search-select-field__value-container {
			padding-left: spacing.$xl;
		}
	}

	.search-select-field__menu {
		.search-select-field__option {
			color: colours.$text-neutral-dark;
			
			&.search-select-field__option--is-focused {
				background-color: colours.$bg-neutral-blue;
				color: colours.$text-primary;
			}

			&.search-select-field__option--is-selected {
				background-color: colours.$bg-neutral-blue;
				color: colours.$text-primary;
				font-weight: typography.$font-weight-semi-bold;
			}
		}
	}
}
