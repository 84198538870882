@use "../../styles/colours";
@use "../../styles/borders";

.edit-image-modal {
	width: auto;
	max-width: none;

	.modal-header {
		display: none;
	}

	.modal-content {
		padding-top: 0;
	}
}

.ImageEditModal {
	display: flex;
    flex-direction: row;
    height: 683px;
}

.image-container {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.zoom-icon-wrapper {
	border-radius: 4px;
	width: 2rem;
	height: 2rem;
	cursor: pointer;
	background-color: rgba(colours.$neutral-blue, 0.5);
	transition: 200ms ease;
	
	&:hover {
		background-color: colours.$neutral-blue;
	}
}

.image-edit-zoom-button {
	color: colours.$primary;
	cursor: pointer;
}

.image-edit-zoom-text {
	font-weight: 500;
}

.react-crop-element {
	display: flex;
	justify-content: center;
	align-items: center;
}

.image-to-crop {
	position: relative;
	top: 50%;
	left: 50%;
}

.colour-picker-active {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    opacity: 0.2;
	z-index: 2;
}

.image-edit-divider {
	width: 1px;
	height: 100%;
	background-color: black;
	margin: 0px 1rem;
}

.image-edit-preview-header {
	font-weight: 500;
}

.image-edit-preview {
	margin-top: 3px;
	overflow: hidden;
    border-radius: 4px;
	border: borders.$border-default;
}
