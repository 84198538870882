@use "../styles/colours";
@use "../styles/mixins";
@use "../styles/sizing";
@use "../styles/spacing";
@use "../styles/borders";
@use "../styles/typography";

.popup-menu-item-wrapper {
	@include mixins.ul-reset;
	@include mixins.li-reset;
	@include mixins.flex-align-center;
	color: colours.$text-neutral-dark;
	// Custom padding-y to make items exactly 40px tall
	padding: 0.75rem spacing.$s;
	border-radius: borders.$radius-default;
	width: 100%;
	position: relative;
	cursor: pointer;

	&:hover {
		background-color: colours.$bg-neutral-blue;
		color: colours.$text-primary;
	}
}

.popup-menu-item-icon-wrapper {
	margin-right: spacing.$s;
	@include mixins.flex-center;
	
	svg {
		color: colours.$text-primary;
		width: sizing.$icon-s;
		height: sizing.$icon-s;
	}
}

.popup-menu-item-small {
	// Custom padding-y to make items exactly 32px tall
	padding: 0.563rem spacing.$s;
	font-size: typography.$font-size-s;
}

.popup-menu-item-no-radius {
	border-radius: 0;

	&:first-of-type {
		border-top-left-radius: borders.$radius-default;
		border-top-right-radius: borders.$radius-default;
	}

	&:last-of-type {
		border-bottom-left-radius: borders.$radius-default;
		border-bottom-right-radius: borders.$radius-default;
	}
}

.popup-menu-item-disabled {
	opacity: 0.5;
	pointer-events: none;
}