@use "../../../styles/colours";
@use "../../../styles/spacing";
@use "../../../styles/borders";
@use "../../../styles/animations";
@use "../../../styles/typography";

.version-option {
	padding: spacing.$l;
	border-top: borders.$border-default;
	background-color: colours.$neutral-light;
	transition: animations.$transition-default;
	color: colours.$neutral-dark;
	position: relative;
	cursor: pointer;

	&.nested-version {
		padding: spacing.$m;
		padding-left: spacing.$xxl;
		padding-right: spacing.$l;

		.version-date-name {
			font-size: typography.$font-size-s;
		}
	}

	&::before {
		background-color: colours.$primary;
		transition: animations.$transition-default;
		top: 0;
		left: 0;
		content: "";
		position: absolute;
		width: 0;
		height: 100%;
	}

	&:hover {
		background-color: rgba(colours.$neutral-blue, 0.4);
		.version-menu {
			opacity: 1;
			cursor: pointer;
		}
	}

	&:last-of-type {
		border-bottom: borders.$border-default;
	}
	
	.version-content {
		flex-grow: 1;
		gap: 12px;
	}

	.version-date {
		color: colours.$neutral-dark-60;
		font-weight: typography.$font-weight-regular;
	}
	
	.version-menu {
		opacity: 0;

		&.menu-showing {
			opacity: 1;
			color: colours.$primary;
		}
	}

	// style for current version
	&.current-version {
		.version-date-name, .version-date {
			color: colours.$neutral-dark;
			font-weight: typography.$font-weight-semi-bold;
		}
	}

	&.selected-version {
		background-color: rgba(colours.$neutral-blue, 0.6);

		.version-date-name {
			font-weight: typography.$font-weight-semi-bold;
		}

		.version-date {
			color: colours.$neutral-dark;
			font-weight: typography.$font-weight-medium;
		}

		&::before {
			width: 2px;
		}
	}

	.version-right-content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-end;
		height: 42px;
	}

	.version-expand-icon {
		transition: 200ms ease;

		&.expanded  {
			transform: rotate(-180deg);
		}
	}
}



