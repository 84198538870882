@use "../../styles/spacing.scss";
@use "../../styles/sizing.scss";
@use "../../styles/colours.scss";
@use "../../styles/animations.scss";
@use "../../styles/mixins.scss";
@use "../../styles/typography.scss";

.toast-close-button {
	@include mixins.width-and-height(sizing.$m);
	opacity: 0.5;

	&:hover {
		opacity: 1;
	}
}	

/** Used to define container behavior: width, position: fixed etc... **/
.Toastify__toast-container {
	padding: 0;
}

/** Used to define the position of the ToastContainer **/
.Toastify__toast-container--top-left {
	top: 64px;
	left: spacing.$m;
}
.Toastify__toast-container--top-center {
	top: 64px;
}
.Toastify__toast-container--top-right {
	top: 64px;
	right: spacing.$m;
}

/** Classes for the displayed toast **/
.Toastify__toast {
	gap: sizing.$xs;
	display: flex;
	align-items: center;
	min-height: 64px;
	transition: animations.$transition-fast;

	&:hover {
		transform: scale(1.03);
	}
}

.Toastify__toast-body {
	padding: spacing.$s;
	font-family: typography.$font-family-primary;
	padding-left: 0;
	color: inherit;
}

/** Used to position the icon **/
.Toastify__toast-icon {
}

/** handle the notificaiton color and the text color based on the theme **/
.Toastify__toast--default {
	color: colours.$text-neutral-dark;
	background-color: colours.$bg-neutral-light;
}

.Toastify__toast--info {
	color: colours.$text-primary;
	background-color: colours.$bg-neutral-blue;
}

.Toastify__toast--success {
	color: colours.$text-positive-dark;	
	background-color: colours.$bg-positive-light;
}

.Toastify__toast--warning {
	color: colours.$text-warning-dark;	
	background-color: colours.$bg-warning-light;
}

.Toastify__toast--error {
	color: colours.$text-danger-dark;
	background-color: colours.$bg-danger-light;
}

.Toastify__progress-bar {
	background: colours.$bg-neutral-blue;
}
.Toastify__progress-bar--rtl {
}
.Toastify__progress-bar-theme--light {
	background: colours.$bg-primary;
}
.Toastify__progress-bar--info {
	background: colours.$bg-primary;
}
.Toastify__progress-bar--success {
	background: colours.$bg-positive-dark;
}
.Toastify__progress-bar--warning {
	background: colours.$bg-warning-dark;
}
.Toastify__progress-bar--error {
	background: colours.$bg-danger-dark;
}

.toast-undo-button {
	font-size: typography.$font-size-s;
	height: sizing.$xl;
	min-width: auto;
}