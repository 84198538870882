@import "../../../styles/_animations.scss";

.page-content-filters {
	padding-left: 106px;
	padding-right: 80px;
	padding-top: 50px;
	display: flex;
	animation: fadeUp 400ms ease;

	.page-content-inner {
		display: flex;

		& > :not(:first-child) {
			margin-left: 40px;
		}
	}
}
