@use "../../styles/colours";

.filter-icon-wrapper {
    opacity: 0.3;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }

    &.filter-active {
        opacity: 1;
    }

    &.filter-disabled {
        pointer-events: none;
    }
}