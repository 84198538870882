@use "../../styles/colours";

.loading-icon-outer {
	.threeskye-loading-icon-outer-ring {
		fill: rgba(colours.$bg-primary, 0.1);
	}

	.threeskye-loading-icon-inner-ring {
		fill: colours.$bg-primary;
	}
	
	&.loading-icon-variant-dark {
		.threeskye-loading-icon-outer-ring {
			fill: rgba(colours.$bg-accent-2, 0.1);
		}
	
		.threeskye-loading-icon-inner-ring {
			fill: colours.$bg-accent-2;
		}
	}
	
	&.loading-icon-variant-light {
		.threeskye-loading-icon-outer-ring {
			fill: rgba(colours.$bg-neutral-blue, 0.1);
		}
	
		.threeskye-loading-icon-inner-ring {
			fill: colours.$bg-neutral-blue;
		}
	}

	&.loading-icon-centered {
		width: 100%;
		text-align: center;
	}
}