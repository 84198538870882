@use "../../styles/components/inputs";
@use "../../styles/mixins";
@use "../../styles/spacing";
@use "../../styles/colours";
@use "../../styles/typography";
@use "../../styles/animations";

.search-field-with-icon {
	position: relative;

	.search-icon {
		@include mixins.width-and-height(inputs.$size-icon-default);
		left: spacing.$s;
		top: 50%;
		transform: translateY(-50%);
		color: inputs.$colour-icon-default;
		position: absolute;
		z-index: 1;
	}

	&:focus-within {
		.search-icon {
			color: inputs.$colour-icon-default-focused;
		}
	}
}

.text-input-wrapper-invalid {
	.search-select-field__control {
		background-color: inputs.$colour-bg-invalid;
		border: inputs.$border-invalid;

		.search-select-field__placeholder {
			color: rgba(inputs.$colour-text-invalid, 0.3);
		}

		&:hover {
			.search-select-field__placeholder {
				color: rgba(inputs.$colour-text-invalid, 0.3);
			}
		}

		&.search-select-field__control--menu-is-open {
			&:hover {
				.search-select-field__placeholder {
					color: inputs.$colour-text-default-placeholder;
				}
			}
		}
	}
}