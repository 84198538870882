@use "../../../styles/spacing";

.sidebar-extension-link-list {
    .sidebar-extension-link {
        margin-bottom: spacing.$m;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}