@use "../../../styles/colours";
@use "../../../styles/mixins";
@use "../../../styles/spacing";
@use "../../../styles/sizing";
@use "../../../styles/typography";
@use "../../../styles/animations";
@use "../../../styles/components/inputs";

$idd-width: 64px;

.text-input-helper-text {
	font-size: typography.$font-size-s;
	margin-top: spacing.$xs;
	color: colours.$text-neutral-dark;
	animation: animations.$transition-fade-up;
}

.phone-number-edit-container {
	position: relative;
	background-color: inputs.$colour-bg-default;
	border: inputs.$border-default;
	border-radius: inputs.$border-radius-default;
	color: inputs.$colour-text-default;
	font-size: inputs.$font-size-default;
	padding: inputs.$padding-default;
	height: inputs.$size-height-default;
	font-family: typography.$font-family-primary;
	transition: animations.$transition-default;
	width: 100%;

	&.phone-field-invalid {
		background-color: inputs.$colour-bg-invalid;
		border: inputs.$border-invalid;
		color: inputs.$colour-text-invalid;

		&:before {
			background-color: rgba(inputs.$colour-text-invalid, 0.6);
		}

		.phone-number-field {
			color: inputs.$colour-text-invalid;

			&::placeholder{
				color: rgba(inputs.$colour-text-invalid, 0.3);
			}

			&:hover {
				&::placeholder{
					color: rgba(inputs.$colour-text-invalid, 0.5);
				}
			}
		}

		.search-select-field__control {
			color: inputs.$colour-text-invalid;

			.search-select-field__single-value {
				color: inputs.$colour-text-invalid;
			}

			.search-select-field__value-container {
				color: inputs.$colour-text-invalid;
			}

			.search-select-field__placeholder {
				color: rgba(inputs.$colour-text-invalid, 0.3);
				
				&:hover {
					color: rgba(inputs.$colour-text-invalid, 0.5);
				}
			}
		}

		&:focus-within {
			color: inputs.$colour-text-default-focused;
			background-color: inputs.$colour-bg-default-focused;
			border: inputs.$border-invalid-focused;
			box-shadow: inputs.$shadow-default-focused;
			
			.phone-number-field {
				color: inputs.$colour-text-default-focused;

				&::placeholder{
					color: inputs.$colour-text-default-placeholder-focused;	
				}

				&:focus{
					&::placeholder{
						color: inputs.$colour-text-default-placeholder-focused;	
					}
				}

				&::selection {
					background-color: rgba(colours.$bg-danger, 0.6);
				}
			}

			.search-select-field__control {
				color: inputs.$colour-text-default-focused;
	
				.search-select-field__value-container {
					color: inputs.$colour-text-default-focused;
				}
	
				.search-select-field__placeholder {
					color: inputs.$colour-text-default-placeholder-focused;	
				}

				.search-select-field__single-value {
					color: inputs.$colour-text-default-focused;
				}

				&.search-select-field__control--is-focused {
					.search-select-field__placeholder {
						color: inputs.$colour-text-default-placeholder-focused;	
					}
					.search-select-field__single-value {
						color: inputs.$colour-text-default-focused;
					}
				}
			}
		}
	}

	&:before {
		content: "";
		position: absolute;
		left: 72px;
		top: 50%;
		transform: translateY(-50%);
		height: 20px;
		width: 1px;
		background-color: colours.$neutral-dark-30;
	}

	&:focus-within {
		color: inputs.$colour-text-default-focused;
		background-color: inputs.$colour-bg-default-focused;
		border: inputs.$border-default-focused;
		box-shadow: inputs.$shadow-default-focused;
		
		&::placeholder{
			color: inputs.$colour-text-default-placeholder-focused;	
		}
		
		.text-input-icon{
			color: inputs.$colour-icon-default-focused;
		}
	}

	.phone-number-field {
		position: absolute;
		left: 80px;
		top: 0;
		border: none;
		background-color: transparent;
		outline: none;
		color: inputs.$colour-text-default;
		font-size: inputs.$font-size-default;
		padding: inputs.$padding-default;
		height: inputs.$size-height-default;
		font-family: typography.$font-family-primary;
		transition: animations.$transition-default;
		width: calc(100% - 80px);

		&::placeholder{
			color: inputs.$colour-text-default-placeholder;
			transition: animations.$transition-default;
		}
		
		&:hover{
			
			&::placeholder{
				color: inputs.$colour-text-default-placeholder-hover;			
			}
		}

		&:focus, &:focus-within {
			border: none;
			background-color: transparent;
			outline: none;
			color: inputs.$colour-text-default-focused;
			
			&::placeholder{
				color: inputs.$colour-text-default-placeholder-focused;	
			}
		
		}
	}
}

.phone-select-field {
	width: $idd-width;
	.search-select-field__control {
		width: 100%;
		padding: 0;
		margin: 0;
		border: none;
		background-color: transparent;
		outline: transparent;
		box-shadow: none;

		&.search-select-field__control--is-focused, &:hover {
			border: none;
			background-color: transparent;
			outline: transparent;
			box-shadow: none;
			padding: 0;
			margin: 0;
		}

		.search-select-field__value-container {
			padding: 0;

			.prefix-label {
				display: none;
			}
		}
	}

	.search-select-field__menu {
		width: 220px;
	}
}


.text-input-helper-text-invalid {
	color: colours.$text-danger-dark;
	animation: animations.$shake;
	font-size: typography.$font-size-s;
	margin-top: spacing.$xs;
}