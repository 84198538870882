$default: 1rem;
$xs: 0.25rem;
$s: 0.5rem;
$m: 1rem;
$l: 1.5rem;
$xl: 2rem;
$xxl: 2.5rem;

$icon-s: 1rem;
$icon-m: 1.5rem;
$icon-l: 2rem;
$icon-xl: 2.5rem;