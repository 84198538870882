@use "../../../styles/colours";
@use "../../../styles/sizing";
@use "../../../styles/spacing";
@use "../../../styles/borders";
@use "../../../styles/mixins";
@use "../../../styles/typography";

.roles-permissions-title {
	padding-right: 15% !important;

	.text-input {
		background-color: #fff;
	}
}

.role-permission-row {
	.checkbox-disabled {
		.checkbox-field-box {
			opacity: 0.4;
		}
	}
}