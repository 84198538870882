@use "../../styles/animations";
@use "../../styles/colours";
@use "../../styles/spacing";
@use "../../styles/sizing";
@use "../../styles/mixins";

.suggestion-message {
	animation: animations.$transition-default;

	p {
		color: colours.$neutral-dark-50;
	}
}