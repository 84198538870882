@use "../../../styles/colours";
@use "../../../styles/typography";
@use "../../../styles/spacing";
@use "../../../styles/sizing";
@use "../../../styles/borders";
@use "../../../styles/mixins";

.alert-banner-wrapper {
	@include mixins.flex-center;
	background-color: colours.$bg-neutral-blue;
	padding: spacing.$m;
	gap: spacing.$s;
	grid-area: alert-banner;
	height: 100%;
	width: 100%;
	display: none;
}

.alert-banner-message {
	color: colours.$text-primary;
	font-size: typography.$font-size-s;
	font-weight: typography.$font-weight-semi-bold;
}

.alert-banner-icon {
	@include mixins.width-and-height(sizing.$icon-s);
	flex-shrink: 0;

	svg {
		@include mixins.width-and-height(100%);
		color: colours.$text-primary;
	}
}

.alert-type-positive {
	background-color: colours.$bg-positive-light;
	
	.alert-banner-message {
		color: colours.$text-positive-dark;
	}
	
	.alert-banner-icon {
		svg {
			color: colours.$text-positive-dark;
		}
	}
}

.alert-type-warning {
	background-color: colours.$bg-warning-light;

	.alert-banner-message {
		color: colours.$text-warning-dark;
	}
	
	.alert-banner-icon {
		svg {
			color: colours.$text-warning-dark;
		}
	}
}

.alert-type-danger {
	background-color: colours.$bg-danger-light;

	.alert-banner-message {
		color: colours.$text-danger-dark;
	}
	
	.alert-banner-icon {
		svg {
			color: colours.$text-danger-dark;
		}
	}
}