@use "../../styles/colours";
@use "../../styles/borders";
@use "../../styles/mixins";
@use "../../styles/spacing";
@import "../../styles/EditorVariables.scss";

#craigs-library-page-body {
	padding-top: 0;

	.table-wrapper {
		overflow: unset;
	}
}

#craigs-library-table {
	th {
		position: sticky;
		top: 0;
		padding-top: 2rem;
		background-color: #fff;
		z-index: 1;
	}

	.library-row-expanded {
		background-color: rgba(colours.$neutral-blue, 0.5);
		th, td {
			background-color: transparent;
		}
	}
	
	.library-row-selected, .library-row-selected td{
		background-color: colours.$neutral-blue;
	}

	.research-title {
		padding-right: spacing.$xl;

		p {
			@include mixins.line-clamp(2);
		}
	}

	.pointer {
		cursor: pointer;
	}
}

.published-icon {
	color: colours.$neutral-dark-30;
	cursor: pointer;

	&:hover {
		opacity: 0.7;
	}

	&.active {
		color: colours.$primary;
	}
}
