@use "../../styles/components/inputs";
@use "../../styles/animations";
@use "../../styles/shadows";
@use "../../styles/layout/layers";
@use "../../styles/colours";
@use "../../styles/mixins";

.top-bar {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 3rem;
	display: flex;
	justify-content: space-between;
	padding:  0 1rem;
	align-items: center;

	.top-bar-left-container {
		display: flex;
		align-items: center;	
	}

	.top-bar-logo {
		color: white;
		height: 1rem;
		width: auto;
		cursor: pointer;
	}

	.top-bar-right-container {
		display: flex;
		align-items: center;
	}
	
	.top-bar-profile-img {
		width: 2rem;
		height: 2rem;
		border-radius: 1rem;
	}
}