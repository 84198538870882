@use "../../styles/borders";
@use "../../styles/colours";
@use "../../styles/sizing";
@use "../../styles/spacing";
@use "../../styles/mixins";
@use "../../styles/typography";
@use "../../styles/animations";

.tab {
	font-size: typography.$font-size-m;
    font-weight: typography.$font-weight-regular;
    color: colours.$text-neutral-dark;
    padding: spacing.$s;
    transition: background-color animations.$transition-default;
    border-radius: borders.$radius-default;
    background-color: transparent;
    cursor: pointer;
    
    &:hover {
        background-color: colours.$bg-neutral-blue;
    }
}

.active-tab{
	background-color: colours.$bg-neutral-blue;
	
	p{
		font-weight: typography.$font-weight-medium;
		color: colours.$text-primary;
	}
}

.disabled {
	&:hover {
        background-color: white;
    }
	p{
		font-weight: typography.$font-weight-medium;
		opacity: 0.5;
	}
	cursor: default;
}