@use "./colours.scss";

// $colours: (colours.$primary: "0", red: "1", green: "2", yellow: "3", blue: "4", gold: "5", cyan: "6");

$colours: (
	1: colours.$primary,
	2: colours.$purple,
	3: #62d9b7,
	4: #F2CA60,
	5: #C873ED,
	6: #FAC79C,
	7: #16689f,
	8: #17b6a9,
	9: #9990e9,
	10: colours.$positive-dark,
	11: #73b3bf,
	12: colours.$accent-2,
	13: colours.$danger-light,
);

// Colours
@each $colour, $i in $colours {
	.colour-chart-#{$colour} {
		background-color: $i;
	}
	
	.colour-chart-text-#{$colour} {
		color: $i;
	}	
}