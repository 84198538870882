// Transitions
$transition-default: 200ms ease-in-out;
$transition-fast: 150ms ease;
$transition-fade-in: 250ms ease-in forwards fadeIn;
$transition-fade-in-slow: 450ms ease forwards fadeIn;
$transition-fade-in-left: 250ms ease-in forwards fadeInLeft;
$transition-fade-in-left-slow: 450ms ease forwards fadeInLeft;
$transition-fade-in-right: 250ms ease-in forwards fadeInRight;
$transition-fade-in-right-slow: 450ms ease forwards fadeInRight;
$transition-fade-out: 250ms ease-out;
$transition-fade-down: 150ms forwards fadeDown;
$transition-fade-up: 150ms forwards fadeUp;
$transition-fade-up-slow: 450ms ease forwards fadeUp;
$transition-fade-down-offset: 150ms forwards fadeDownOffset;
$transition-hover: 300ms ease-in;
$transition-grow-right: 1s ease forwards growRight;

$shake: 350ms forwards shake;

@keyframes fadeUp {
	0% {
		opacity: 0;
		transform: translateY(5px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes fadeInLeft {
	0% {
		opacity: 0;
		transform: translateX(15px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes fadeInRight {
	0% {
		opacity: 0;
		transform: translateX(-5px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeDown {
	0% {
		opacity: 0.3;
		margin-top: -3px;
	}
	100% {
		opacity: 1;
		margin-top: 0;
	}
}

@keyframes fadeDownOffset {
	0% {
		opacity: 0.3;
		margin-top: 0;
	}
	100% {
		opacity: 1;
		margin-top: 4px;
	}
}

@keyframes shake {
	0% {
		transform: translateX(1px);
	}
	25% {
		transform: translateX(-1px);
	}
	50% {
		transform: translateX(1px);
	}
	75% {
		transform: translateX(-1px);
	}
	100% {
		transform: translateX(0);
	}
}


@keyframes growRight {
	0% {
		// opacity: 0.3;
		transform: scaleX(70%);
		transform-origin: left;
	}
	100% {
		// opacity: 1;
		transform: scaleX(100%);
		transform-origin: left;
	}
}
