@use "../styles/animations";
@use "../styles/colours";
@use "../styles/typography";
@use "../styles/mixins";
@use "../styles/sizing";
@use "../styles/borders";

.radio-wrapper {
	cursor: pointer;

	&.disabled {
			.radio-icon-wrapper {
				&:not(.radio-checked) {
					color: colours.$neutral-dark-50;
				}
			}
	
		.radio-icon-wrapper {
			color: colours.$neutral-dark-50;
		
			&:after {
				background-color: colours.$neutral-dark-50;				
			}
			
			&.radio-checked {
				color: colours.$neutral-dark-50;
			}
		}
	}

	&:hover {
		.radio-icon-wrapper {
			&:not(.radio-checked) {
				color: colours.$neutral-dark-50;
			}
		}
	}

	.radio-icon-wrapper {
		color: colours.$neutral-dark-30;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	
		&:after {
			content: "";
			transition: 130ms ease;
			width: 5px;
			height: 5px;
			background-color: colours.$primary;
			position: absolute;
			border-radius: 50%;
			opacity: 0;
		}
	
		.radio-icon {
			width: sizing.$m;
			height: sizing.$m;
		}
	
		&.radio-checked {
			color: colours.$primary;
	
			&:after {
				width: 8px;
				height: 8px;
				opacity: 1;
			}
		}
	}
}