@use "../../../styles/spacing";
@use "../../../styles/colours";
@use "../../../styles/sizing";
@use "../../../styles/typography";

.research-list-row {
	// padding: 2rem 1rem;
	border-top: none;
	background-color: colours.$bg-neutral-light;
	border-radius: 0.5rem;
	padding: 1rem;

	.research-ticker-name {
		font-size: 0.85rem;
		font-weight: 500;
		color: colours.$neutral-dark-60;
		position: relative;
	}

	.research-content-wrapper {
		overflow: hidden;
	}

	.research-info {
		width: 98%;
	}

	.research-title {
		font-size: typography.$font-size-l;
		font-weight: typography.$font-weight-medium;
	}

	.research-preview {
		font-size: typography.$font-size-m;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;  
		overflow: hidden;

		textarea {
			padding: 0;
			transition: 200ms ease;
			height: auto;
			background-color: transparent;
			border: none;
		}

		&:focus-within {
			textarea {
				padding: 0.3rem 0.3rem;
				border: 1px solid colours.$primary;
				background-color: colours.$neutral-light;
			}
		}
	}

	&:hover {
		textarea {
			background-color: colours.$neutral-blue;
		}
	}

	.research-details {
		font-size: typography.$font-size-s;
		color: colours.$neutral-dark-60;
		// opacity: 0;
		// transform: translateY(5px);
	}

	&:hover {
		.research-content-wrapper, .research-details {
			transform: translateY(0);
			opacity: 1;
		}

		.ts-image-container {
			img {
				transform: scale(1.1);
			}
		}

		.ts-image-overlay {
			opacity: 1;
		}
	}

	.status-token-outer {
		background-color: #AF7747;
		
		h5 {
			color: #fff;
		}
	}

	
}