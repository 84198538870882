@use "../../../styles/colours";
@use "../../../styles/sizing";
@use "../../../styles/spacing";
@use "../../../styles/borders";
@use "../../../styles/animations";
@use "../../../styles/mixins";
@use "../../../styles/layout/layers";
@use "../../../styles/shadows";

.model-portfolio-banner {
	display: flex;
	align-items: center;
	height: 100%;
	gap: 0.5rem;
}
.model-portfolio-banner-title {
	font-size: 20px;
}

.model-portfolio-banner-icon {
	color: colours.$primary;
	height: 20px;
	width: 20px;
	transform: translateY(-1px);
	&.small {
		height: 16px;
		width: 16px;
	}
}

.model-portfolio-class-name {
	display: flex;
	align-items: center;
	&:hover {
		.model-portfolio-class-edit-icon{
			display: block;
		}
	}
}
.model-portfolio-class-edit-icon {
	display: none;
	color: colours.$primary;
	height: 1rem;
	width: 1rem;
	margin-left: 0.5rem;
}

.model-portfolio-class-save-icon {
	display: none;
	color: colours.$primary;
	height: 1rem;
	width: 1rem;
	// margin-left: 0.5rem;
}

.model-portfolio-class-input {
	border: none;
	background: none;
	font-size: 1rem;
	width: fit-content;

	&:focus-visible {
		outline: none;
		outline-offset: 0;
	}
}

.model-portfolio-add-class-row {
	display: flex;
	height: 2.5rem;
	border-bottom: 1px solid #E7EAF2;
	background-color: #F2F5FC;
	align-items: center;
	padding-left: 0.5rem;
	gap: 0.5rem;
	color: #333B5A;
	cursor: pointer;
	&:hover {
		background-color: rgba(#F2F5FC, 0.5);
	}
}

.model-portfolio-edit-asset-weighting {
	display: none;
	color: colours.$primary;
	height: 1rem;
	width: 1rem;
	margin-left: 0.5rem;
}

.model-portfolio-asset-weighting {
	input[type="number"]::-webkit-inner-spin-button,
	input[type="number"]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	.model-portfolio-asset-weighting-edit-percentage-sign {
		position: absolute;
		top: 50%;
		right: 33px;
		transform: translateY(-50%);
	}

	&:hover {
		.model-portfolio-edit-asset-weighting{
			display: block;
		}
	}

	.model-portfolio-edit-weighting-edit-input-background {
		width: 75%;
		input {
			background-color: #FFFFFF;
		}
	}
}

.model-portfolio-ticker-detail-name {
	font-size: 13px;
	color: colours.$neutral-dark-40;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.model-portfolio-icons {
	display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 66px;
    margin: auto;
    white-space: nowrap;
}

.model-portfolio-right-align {
	width: 90%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.model-portfolio-recommendation {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}