@use "../../styles/colours";
@use "../../styles/spacing";
@use "../../styles/sizing";
@use "../../styles/mixins";
@use "../../styles/typography";

$modal-height: 488px;

.image-browser-modal {
	height: $modal-height;
	width: 768px;
	max-width: none;
	position: relative;

	.modal-header {
		right: spacing.$m;
		top: spacing.$m;
		position: absolute;
	}

	.modal-content {
		margin: 0;
		padding: 0;
		height: 100%;
		width: 100%;
		display: flex;
	}
}

.image-browser-modal-sidebar {
	height: $modal-height;
	background-color: rgba(colours.$bg-neutral-dark, 0.03);
	padding-top: spacing.$xl;
	width: 192px;
	position: relative;

	.sidebar-categories {
		.sidebar-extension-link {
			width: 100%;
		}
	}

	.finish-button-wrapper {
		padding: 0 spacing.$m;
		bottom: spacing.$xl;
		width: 100%;
		position: absolute;
		left: 0;
	}
}

.selected-image-container {
	@include mixins.flex-justify-center;
	gap: spacing.$s;
	width: 100%;
	flex-wrap: wrap;

	.upload-button {
		flex-direction: row-reverse;

		.upload-icon {
			color: colours.$text-primary;
			margin-left: spacing.$s;
			margin-right: 0;
		}
	}
}

.image-browser-modal-gallery {
	@include mixins.hide-scrollbars;
	height: 253px;
	padding-top: spacing.$xxl;
	padding-left: spacing.$m;
	padding-right: spacing.$m;
	padding-bottom: spacing.$l;
	overflow: auto;
}