@use "./styles/layout/grid";
@use "./styles/colours";
@use "./styles/spacing";
@use "./styles/typography";
@use "./styles/borders";

body {
	font-family: typography.$font-family-primary;
	background-color: colours.$bg-editor-grey !important;
	overflow: hidden;
}

p {
	color: colours.$text-neutral-dark;
	font-size: typography.$font-size-m;
	line-height: typography.$line-height-m;
}

h1 {
	font-size: typography.$font-size-header-1;
	font-weight: typography.$font-weight-medium;
	color: colours.$text-neutral-dark;
	margin: 0;
}

h2 {
	font-size: typography.$font-size-header-2;
	font-weight: typography.$font-weight-medium;
	color: colours.$text-neutral-dark;
	margin: 0;
}

h3 {
	font-size: typography.$font-size-header-3;
	font-weight: typography.$font-weight-medium;
	color: colours.$text-neutral-dark;
	margin: 0;
}

h4 {
	font-size: typography.$font-size-header-4;
	font-weight: typography.$font-weight-medium;
	color: colours.$text-neutral-dark;
	margin: 0;
}

h5 {
	font-size: typography.$font-size-header-5;
	font-weight: typography.$font-weight-medium;
	color: colours.$text-neutral-dark;
	margin: 0;
}

h6 {
	font-size: typography.$font-size-header-6;
	font-weight: typography.$font-weight-semi-bold;
	color: colours.$text-neutral-dark;
	margin: 0;
}

label {
	font-size: typography.$font-size-s;
	font-weight: typography.$font-weight-regular;
	color: colours.$bg-neutral-dark;
}

ul, ol {
	padding-left: 24px;
	margin: 0;
}