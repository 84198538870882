@use "../styles/components/inputs";
@use "../styles/animations";
@use "../styles/shadows";
@use "../styles/layout/layers";
@use "../styles/colours";
@use "../styles/spacing";
@use "../styles/sizing";
@use "../styles/typography";
@use "../styles/mixins";

.icon-counter-wrapper {
	@include mixins.flex-align-center;
	display: inline-flex;
	gap: spacing.$xs;

	svg {
		@include mixins.width-and-height(sizing.$icon-s);
		color: colours.$text-primary;
	}
	
	&.icon-counter-status-default svg {
		color: colours.$primary;
	}

	&.icon-counter-status-success svg {
		color: colours.$text-positive;
	}

	&.icon-counter-status-delivered svg {
		color: colours.$text-positive;
	}

	&.icon-counter-status-fail svg {
		color: colours.$text-danger;
	}

	&.icon-counter-status-read svg {
		color: colours.$text-purple;
	}

	&.counter-inactive {
		opacity: 0.1;
		svg {
			color: colours.$neutral-dark;
		}
	}
}

.icon-counter-count {
	font-size: typography.$font-size-m;
	color: colours.$text-neutral-dark;
}

.icon-counter-empty {
	color: colours.$text-label-grey;
}