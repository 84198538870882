@use "../../styles/colours";

#analytics-page {
	.db {
		border-radius: 4px;
		padding: 16px;
		background-color: rgb(244, 244, 244);

		h4 {
			color: colours.$label-grey;
		}
	}
}