@import "../../styles/_colours.scss";


.audit-log-file-row {
	cursor: pointer;
	&:hover {
	.nested-table-empty-td {
			background-color: rgba($neutral-dark, 0.1) !important;
		}
	}
}