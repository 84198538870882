@use "../../../styles/colours";

#admin-disclaimer-page {
	.upload-section {
		max-width: 500px;
		min-width: 500px;
	}

	.uploaded-file-container {
		margin-top: 20px;
		background-color: colours.$bg-button-grey;
        height: calc(100vh - 330px);
        max-height: 800px;
	}
}