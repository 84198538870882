@use "../../styles/colours";
@use "../../styles/spacing";
@use "../../styles/sizing";
@use "../../styles/mixins";
@use "../../styles/borders";
@use "../../styles/layout/layers";

@mixin sidebar {
	@include mixins.flex-align-center;
	flex-direction: column;
	gap: spacing.$m;
}

.side-bar-wrapper {
	@include sidebar;
	padding: spacing.$l spacing.$m;
	padding-bottom: spacing.$xxl;
	z-index: layers.$layer-3;
	grid-area: sidebar-left;
	height: 100%;
	width: 100%;

	&.sidebar-left {
		grid-area: sidebar-left;
	}

	&.sidebar-right {
		grid-area: sidebar-right;
	}
}

.side-bar-inner-icons {
	@include sidebar;
	padding-bottom: spacing.$m;

	&.side-bar-icon-group-divider {
		border-bottom: borders.$width-default borders.$style-default borders.$colour-default;

		&:last-of-type {
			border-bottom: none;
			padding-top: spacing.$m;
			border-top: borders.$width-default borders.$style-default borders.$colour-default;
		}

		&:only-child {
			border-top: none;
		}
	}

	&.side-bar-inner-top {
		padding-bottom: spacing.$l;
	}
}