@use "../../../styles/colours";
@use "../../../styles/typography";
@use "../../../styles/spacing";
@use "../../../styles/sizing";
@use "../../../styles/borders";
@use "../../../styles/mixins";

.alert-block-wrapper {
	border-radius: borders.$radius-default;
	min-height: sizing.$xl;
	background-color: colours.$bg-neutral-blue;
	padding: spacing.$m;
	gap: spacing.$m;
	display: inline-flex;
	align-items: center;
	margin-bottom: spacing.$m;

	&:only-child, &:last-of-type {
		margin-bottom: 0;
	}

	&.alert-type-has-header {
		.alert-block-message {
			font-weight: typography.$font-weight-regular;
		}
	}

	&.alert-size-small {
		padding: spacing.$s;
		gap: spacing.$s;

		.alert-block-icon {
			@include mixins.width-and-height(sizing.$icon-s);
		}
	}
	
}

.alert-block-message {
	color: colours.$text-primary;
	font-size: typography.$font-size-m;
	font-weight: typography.$font-weight-medium;
	line-height: typography.$line-height-m;
	
	h4 {
		color: inherit;
		font-weight: typography.$font-weight-bold;
		margin-bottom: 	spacing.$xs;
	}
}

.alert-block-icon {
	@include mixins.width-and-height(sizing.$icon-m);
	flex-shrink: 0;

	svg {
		@include mixins.width-and-height(100%);
		color: colours.$text-primary;
	}
}

.alert-type-positive {
	background-color: colours.$bg-positive-light;
	
	.alert-block-message {
		color: colours.$text-positive-dark;
	}
	
	.alert-block-icon {
		svg {
			color: colours.$text-positive-dark;
		}
	}
}

.alert-type-warning {
	background-color: colours.$bg-warning-light;

	.alert-block-message {
		color: colours.$text-warning-dark;
	}
	
	.alert-block-icon {
		svg {
			color: colours.$text-warning-dark;
		}
	}
}

.alert-type-danger {
	background-color: colours.$bg-danger-light;

	.alert-block-message {
		color: colours.$text-danger-dark;
	}
	
	.alert-block-icon {
		svg {
			color: colours.$text-danger-dark;
		}
	}
}

$sizes: 'xs' spacing.$xs,'s' spacing.$s, 'm' spacing.$m, 'l' spacing.$l, 'xl' spacing.$xl, 'xxl' spacing.$xxl;

@each $size, $i in $sizes {
	.alert-spacing-#{$size} {
		padding: $i;
	}	
}