@use "../../../styles/colours";
@use "../../../styles/spacing";
@import "../../../styles/EditorVariables.scss";

.sidebar-footer {
    // same width as sidebar and sidebar-content-wrapper.
    width: 272px;
    background-color: colours.$neutral-light;
    // border-top: 1px solid rgba($colour-divider, 0.7);
    overflow: hidden;
    opacity: 1;
    // transition-delay: 0, 300ms;
    transition: left 600ms, width 600ms;
    position: relative;
    
    &.fixed {
        position: fixed;
        bottom: 0;
        // left: 5rem;

        &::before {
            width: calc(100%);
            box-shadow: 0px -2px 5px rgba(#000, 20%);
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 1px;
            // background-color: #fff;
        }
    }

    .sp-circle {
        margin: 0;
        margin-right: 10px;
        width: 20px;
        height: 20px;
    }

    .button-success {
        .sp-circle {
            border-color: rgba(#fff, 0.25);
            border-top-color: #fff;
        }
    }
}

.sidebar-footer-content {
    padding: 25px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;
}

.sidebar-footer-buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.sidebar-footer-text-button {
    font-size: 14px;
    opacity: 0.8;
    cursor: pointer;
    
    &:hover {
        opacity: 1;
    }
}

.sidebar-footer-links {
    width: 100%;
}
