@use "../../../styles/colours";
@use "../../../styles/mixins";
@use "../../../styles/spacing";
@use "../../../styles/sizing";

.detail-section-header {
	@include mixins.flex-align-center;
	gap: spacing.$s;

	svg {
		@include mixins.width-and-height(sizing.$l);
		color: colours.$text-primary;
	}
}