//Base
$neutral-light:#FFF;
$neutral-blue: #F2F5FC;
$neutral-dark: #333B5A;
$primary: #0E8EFD;
$accent-1: #0A70C9;
$accent-2: #06467D;

//State
$positive-dark: #00805e;
$positive: #56D1C3;
$positive-light: #E0FFF6;
$warning: #f8dc64;
$warning-dark: #814F00;
$warning-light: #FFE1B2;
$danger-dark: #A0001E;
$danger: #E66780;
$danger-light: #FFD1DD;

// Tints
$neutral-dark-90: #474F6B;
$neutral-dark-80: #5C627B;
$neutral-dark-70: #70768C;
$neutral-dark-60: #85899C;
$neutral-dark-50: #999DAD;
$neutral-dark-40: #ADB1BD;
$neutral-dark-30: #C2C4CE;
$neutral-dark-20: #D6D8DE;
$neutral-dark-10: #EBEBEF;

//Other
$pdf: #CC2949;
$xls: #1D6F42;
$purple: #6C7EE0;
$border-grey: #E7EAF2;
$input-grey: #F4F6FA;
$disabled-grey: #FBFBFD;
$button-grey: #F0F3F8;
$editor-grey: #F4F4F4;
$label-grey: $neutral-dark-40;
$icon-grey: $neutral-dark-20;

//Aliases
//Background
$bg-neutral-light: $neutral-light;
$bg-neutral-blue: $neutral-blue;
$bg-neutral-dark: $neutral-dark;
$bg-neutral-dark-90: $neutral-dark-90;
$bg-neutral-dark-80: $neutral-dark-80;
$bg-neutral-dark-70: $neutral-dark-70;
$bg-neutral-dark-60: $neutral-dark-60;
$bg-neutral-dark-50: $neutral-dark-50;
$bg-neutral-dark-40: $neutral-dark-40;
$bg-neutral-dark-30: $neutral-dark-30;
$bg-neutral-dark-20: $neutral-dark-20;
$bg-neutral-dark-10: $neutral-dark-10;
$bg-primary: $primary;
$bg-primary-overlay: rgba($primary, 0.8);
$bg-accent-1: $accent-1;
$bg-accent-2: $accent-2;
$bg-positive: $positive;
$bg-positive-dark: $positive-dark;
$bg-positive-light: $positive-light;
$bg-warning: $warning;
$bg-warning-dark: $warning-dark;
$bg-warning-light: $warning-light;
$bg-danger: $danger;
$bg-danger-dark: $danger-dark;
$bg-danger-light: $danger-light;
$bg-positive-dark: $positive-dark;
$bg-positive-light: $positive-light;
$bg-purple: $purple;
$bg-button-grey: $button-grey;
$bg-editor-grey: $editor-grey;

//Text
$text-neutral-light: $neutral-light;
$text-neutral-blue: $neutral-blue;
$text-neutral-dark: $neutral-dark;
$text-neutral-dark-80: $neutral-dark-80;
$text-neutral-dark-50: $neutral-dark-50;
$text-neutral-dark-30: $neutral-dark-30;
$text-primary: $primary;
$text-accent-1: $accent-1;
$text-accent-2: $accent-2;
$text-positive-dark: $positive-dark;
$text-positive-light: $positive-light;
$text-warning-dark: $warning-dark;
$text-warning-light: $warning-light;
$text-danger: $danger;
$text-danger-dark: $danger-dark;
$text-danger-light: $danger-light;
$text-label-grey: $label-grey;
$text-purple: $purple;
$text-danger: $danger;
$text-positive: $positive;

// Border
$border-neutral-light: $neutral-light;
$border-neutral-blue: $neutral-blue;
$border-neutral-dark: $neutral-dark;
$border-primary: $primary;
$border-accent-1: $accent-1;
$border-accent-2: $accent-2;
$border-positive-dark: $positive-dark;
$border-positive-light: $positive-light;
$border-warning-dark: $warning-dark;
$border-warning-light: $warning-light;
$border-danger-dark: $danger-dark;
$border-danger-light: $danger-light;
$border-purple: $purple;
$border-input-grey: $input-grey;
$border-button-grey: $button-grey;
$border-neutral-dark-80: $neutral-dark-80;
$border-neutral-dark-50: $neutral-dark-50;
$border-neutral-dark-30: $neutral-dark-30;