@use "../../styles/borders";
@use "../../styles/colours";
@use "../../styles/mixins";
@use "../../styles/sizing";
@use "../../styles/spacing";
@use "../../styles/typography";

.table-wrapper {
	@include mixins.hide-scrollbars;
	overflow-x: auto;
	
	table {
		width: 100%;
		border-collapse: collapse;
		table-layout: fixed;
		
		thead {
			text-align: left;

			&.fixed-headers {
				th {
					position: sticky;
  					top: 0;
					box-shadow: inset 0 -1px 0 borders.$colour-default;
				}
			}
		}

		th {
			background-color: colours.$neutral-light;
			border-bottom: borders.$border-default;
			font-weight: typography.$font-weight-medium;
			padding-bottom: spacing.$m;
		}

		tr {
			&:hover {
				td {
					background-color: colours.$input-grey;
				}
			}


			&.no-hover {
				td {
					background-color: inherit;
					cursor: default;
				}
			}
		}

		td {
			height: 4.5rem;
			background-color: colours.$neutral-light;
			border-top: borders.$border-default;
			border-bottom: borders.$border-default;
			overflow-x: hidden;
    		text-overflow: ellipsis;
		}

		th, td {
			font-size: typography.$font-size-m;
			color: colours.$text-neutral-dark;
			padding-right: spacing.$s;
			
			&:first-of-type {
				padding-left: spacing.$s;
			}

			&:last-of-type {
				padding-right: spacing.$s;
			}
		}
		
		.tr-borders {
			border-top: 1px solid #E7EAF2;
			border-bottom: 1px solid #E7EAF2;
		}
	}

	.table-action-icons {
		text-align: right;
	}

	&.table-compact {
		table {
			td {
				height: 3.5rem;
			}
		}
	}

	&.table-size-s {
		table {
			td {
				height: 3rem;
			}
		}
	}
	.dragged {
		td {
			display: table-cell;
		}
		td:nth-child(1), td:nth-child(2) {	
			display: table-cell;
			padding: 0 0px 0 0.5rem;
		}
	}
}

.dragged {
	td {
		display: none;
	}
	td:nth-child(1), td:nth-child(2) {	
		display: table-cell;
		padding: 0 20px 0 0.5rem;
	}
}