@use "../styles/colours";
@use "../styles/sizing";
@use "../styles/spacing";
@use "../styles/borders";
@use "../styles/animations";
@use "../styles/mixins";
@use "../styles/layout/layers";
@use "../styles/shadows";

.ticker-item-container {
	@include mixins.flex-align-center;
	background-color: colours.$bg-neutral-light;
	border: borders.$border-neutral-blue;
	border-radius: borders.$radius-default;
	padding: spacing.$s;
	height: 64px;
	transition: background-color animations.$transition-default;
	width: 100%;

	.drag-indicator {
		margin-right: spacing.$s;
		color: colours.$border-grey;
		cursor: grab;
		font-size: 16px;
	}

	&:hover {
		cursor: grab;
	}

	// Dragging styles
	&.drag-item {
		box-shadow: shadows.$default;
		z-index: layers.$layer-9;
		cursor: grabbing;
		transform: translateX(-1px) translateY(-1px);
	}

	// Active/checked styles
	&.ticker-item-checked {
		background-color: colours.$bg-neutral-blue;

		.drag-indicator {
			color: rgba(colours.$border-primary, 0.2);
		}
	}

	.popup-outer {
		cursor: pointer;
		
		.info-icon {
			width: sizing.$m;
			color: colours.$bg-danger;
		}
	}
}

.checkbox-field-wrapper {
	margin-right: spacing.$m;
}

.ticker-detail-wrapper {
	color: colours.$text-neutral-dark;
	background-color: colours.$bg-neutral-blue;
	border-radius: borders.$radius-default;
	padding: spacing.$s;
	display: flex;
	flex-wrap: wrap;
	flex: 1;
	height: 48px;

	.ticker-code {
		width: 100%;
		margin-bottom: spacing.$xs;
	}

	&.status-data-available {
		background-color: colours.$bg-positive-light;
		
		.status-icon, .ticker-code, .ticker-timestamp {
			color: colours.$bg-positive-dark;
		}
	}

	&.status-data-out-of-date {
		background-color: colours.$bg-warning-light;
		
		.status-icon, .ticker-code, .ticker-timestamp {
			color: colours.$bg-warning-dark;
		}
	}

	&.status-data-unavailable {
		background-color: colours.$bg-danger-light;
		
		.status-icon, .ticker-code, .ticker-timestamp {
			color: colours.$bg-danger-dark;
		}
	}
}

.uploader-tooltip {
	display: flex;

	.user-icon {
		color: colours.$primary;
		width: sizing.$m;
	}
}

.checkbox-detail-wrapper {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.ticker-timestamp {
	@include mixins.flex-align-center;
	gap: spacing.$xs;
	width: 100%;
	font-size: 10px;
	color: inherit;

	.status-icon {
		width: sizing.$s;
		height: auto;
	}
}
