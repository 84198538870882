@use "../../../styles/colours";
@use "../../../styles/typography";
@use "../../../styles/borders";
@use "../../../styles/shadows";

.percentage-bar-wrapper {
	.bar-labels {
		font-size: typography.$font-size-s;
		font-family: typography.$font-family-primary;
		color: colours.$neutral-dark;
	}

	.bar-meter {
		position: relative;
		width: 100%;
		background-color: rgba(colours.$primary, 0.05);
		overflow: hidden;
		height: 4px;
		border-radius: 4px;

		.bar-meter-inner {
			background-color: colours.$primary;
			height: 4px;
		}
	}
}