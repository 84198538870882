@use "../../../styles/colours";
@use "../../../styles/borders";
@use "../../../styles/mixins";
@use "../../../styles/spacing";
@use "../../../styles/animations";

.image-browser-modal-gallery {
	height: auto;
}

.model-attachment-icon {
	width: 0.85rem;
	height: 0.85rem;
	color: colours.$primary;
	transform: translateY(0.15rem);
	margin-left: 0.25rem;
	cursor: pointer;

	&:hover {
		opacity: 0.7;
	}
}

.publication-settings-header {
	margin-bottom: 2rem;
}

.publication-settings-wrapper {
	margin-bottom: 1rem;

	tr {
		border-top: 1px solid #E7EAF2;
		border-bottom: 1px solid #E7EAF2;

		&:first-of-type {
			border-top: none;
		}
	}

	.text-area-field {
		height: 72px
	}
	
	.radio-icon-wrapper {
		color: colours.$primary;
	}
	
	.upload-model-cell {
		cursor: pointer !important;
		display: flex;
		align-items: center;
		height: 56.67px;
		border-top: none;
		border-bottom: none;
		gap: spacing.$xs;

		&.no-model {
			.file-icon path {
				fill: colours.$neutral-dark-50; 
			}
		}

		&:hover {
			color: rgba(0,0,0,0.5);
			
		}
	}
}

.publication-settings-td {
	overflow: hidden;
	text-overflow: ellipsis;
}